import React, { useState, useEffect, useRef } from 'react'
import bbox from '@turf/bbox'
import { StaticMap, Source, Layer, WebMercatorViewport } from 'react-map-gl'

import { useAirports } from 'src/hooks'
import {
  MAPBOX_TOKEN,
  MAPBOX_STYLE_URI,
  DEFAULT_MAP_VIEWPORT,
  DEFAULT_LAYER_FILL_COLOR,
} from 'src/constants'

import { Box, Flex, Text } from 'system'
import { MapMarker } from 'src/components'

export const StaticAirportMap = ({
  airportUID,
  geometry,
  geometryColor = DEFAULT_LAYER_FILL_COLOR,
  coordinates,
  markerType,
  ...props
}) => {
  const containerRef = useRef()
  const airportData = useAirports({ airportUID })
  const [viewport, setViewport] = useState()

  const airportCoordinates = airportData?.fields?.polylabel
  const defaultMapCoodinates = [
    DEFAULT_MAP_VIEWPORT.longitude,
    DEFAULT_MAP_VIEWPORT.latitude,
  ]

  useEffect(() => {
    const container = containerRef.current

    if (!container || !geometry) return

    const [minX, minY, maxX, maxY] = bbox(geometry)
    const bounds = [
      [minX, minY],
      [maxX, maxY],
    ]
    const rect = container.getBoundingClientRect()

    const newViewport = new WebMercatorViewport({
      ...DEFAULT_MAP_VIEWPORT,
      width: rect.width,
      height: rect.height,
    }).fitBounds(bounds, { padding: 40 })

    setViewport(newViewport)
  }, [geometry])

  const mapViewportCenterCoordinates =
    (viewport && [viewport.longitude, viewport.latitude]) ||
    (coordinates ?? airportCoordinates ?? defaultMapCoodinates)

  return (
    <Box ref={containerRef} height="500px" position="relative" {...props}>
      {!geometry && !coordinates && (
        <Flex
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex="1"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            bg="white"
            opacity={0.75}
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
          />
          <Text as="p" textAlign="center" position="relative">
            Map information unavailable
          </Text>
        </Flex>
      )}
      <StaticMap
        mapboxApiAccessToken={MAPBOX_TOKEN}
        mapStyle={MAPBOX_STYLE_URI}
        {...DEFAULT_MAP_VIEWPORT}
        {...viewport}
        zoom={Math.min(viewport?.zoom ?? DEFAULT_MAP_VIEWPORT.zoom, 17)}
        longitude={mapViewportCenterCoordinates?.[0]}
        latitude={mapViewportCenterCoordinates?.[1]}
        width="100%"
        height="100%"
      >
        {!geometry && coordinates && (
          <MapMarker
            longitude={coordinates[0]}
            latitude={coordinates[1]}
            type={markerType}
          />
        )}
        {geometry && (
          <Source type="geojson" data={geometry}>
            <Layer
              type="fill"
              paint={{
                'fill-color': geometryColor,
                'fill-opacity': 0.6,
              }}
            />
            <Layer
              type="line"
              paint={{
                'line-color': geometryColor,
                'line-width': 1.5,
              }}
            />
          </Source>
        )}
      </StaticMap>
    </Box>
  )
}
