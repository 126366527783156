import React from 'react'

import { Box, Grid, Text, SVG } from 'system'
import { Subheading } from 'src/components'
import { ReactComponent as AssetIconTriangleSVG } from 'src/assets/triangle.svg'

const StageLabel = ({ children, description, ...props }) => (
  <Grid as={Text} gridGap="0.25rem" {...props}>
    {children && (
      <Subheading
        as="span"
        variant="sansExt"
        fontSizeScale="xt+"
        color="gray.10"
      >
        {children}
      </Subheading>
    )}
    <Subheading as="span" variant="sansExt" fontSizeScale="xt+" color="gray.50">
      {description}
    </Subheading>
  </Grid>
)

const stageVariants = {
  base: {
    withProgressBar: true,
    nameAlignment: 'center',
    shiftName: true,
    namePosition: undefined,
    nameTop: undefined,
  },
  first: {
    withProgressBar: true,
    nameAlignment: 'left',
    shiftName: false,
    namePosition: undefined,
    nameTop: undefined,
  },
}

const Stage = ({
  variant: variantName = 'base',
  name,
  description,
  percentComplete,
  totalStagesCount,
  index,
  showArrow = false,
  ...props
}) => {
  const variant = stageVariants[variantName]

  // Used as grid-row property on mobile. This reverses the order.
  const gridPosition = totalStagesCount - index

  return (
    <Grid
      as="li"
      gridTemplateColumns={['2rem 1fr', 'auto']}
      gridTemplateRows={['4.75rem', '2rem auto']}
      gridAutoFlow="row"
      gridGapScale={['s', 't']}
      gridRow={[gridPosition, 1]}
      gridColumn={[1, index + 1]}
      position={variant.namePosition}
      top={variant.nameTop}
      right={variant.nameRight}
      {...props}
    >
      <Grid bg="teal.90" alignItems="end">
        <Box
          bg="green.50"
          height={[`${percentComplete}%`, '100%']}
          width={['100%', `${percentComplete}%`]}
          position="relative"
        >
          <SVG
            svg={AssetIconTriangleSVG}
            x={8}
            y={6}
            color="green.50"
            display={showArrow ? 'block' : 'none'}
            position="absolute"
            right={['auto', 0]}
            left={[0, 'auto']}
            top={0}
            transform={[
              'translate(calc(-100% - 2px), -55%) rotate(90deg)',
              'translate(55%, calc(-100% - 3px)) scaleY(-1)',
            ]}
            width={['0.7rem', '0.85rem']}
          />
        </Box>
      </Grid>
      <Box alignSelf={['end', 'start']}>
        <StageLabel
          description={description}
          textAlign={[null, variant.nameAlignment]}
          transform={
            variant.shiftName
              ? ['translateY(calc(50% + 1px))', 'translateX(-50%)']
              : 'none'
          }
        >
          {name}
        </StageLabel>
      </Box>
    </Grid>
  )
}

export const ProgressBar = ({
  stageNames = [],
  stageDescriptions = [],
  stage,
  stagePercentCompleted = 0,
  ...props
}) => {
  const currentStageIndex = stageNames.findIndex((name) => stage === name)
  const lastStageIndex = stageNames.length - 1
  const showArrowStageIndex = Math.min(currentStageIndex, stageNames.length - 2)

  return (
    <Box position="relative">
      <Grid
        as="ul"
        gridGap="0.125rem"
        gridAutoFlow={['row', 'column']}
        gridAutoColumns="1fr"
        {...props}
      >
        {stageNames.map((name, i) => {
          // Don't render the last stage here. Do it outside the <ul>.
          if (i >= stageNames.length - 1) return null

          let percentComplete = 0
          if (i < currentStageIndex) percentComplete = 100
          if (i === currentStageIndex) percentComplete = stagePercentCompleted

          let variant = 'base'
          if (i === 0) variant = 'first'

          return (
            <Stage
              key={name}
              variant={variant}
              name={name}
              description={stageDescriptions[i]}
              percentComplete={percentComplete}
              totalStagesCount={stageNames.length}
              index={i}
              showArrow={i === showArrowStageIndex}
            />
          )
        })}
      </Grid>
      <StageLabel
        description={stageDescriptions[lastStageIndex]}
        position="absolute"
        left={['calc(2rem + 20px)', 0]}
        top={[
          '0.25rem',
          'calc(2rem + 12.75px)',
          'calc(2rem + 13.5px)',
          'calc(2rem + 14.25px)',
          'calc(2rem + 15px)',
        ]}
        right={[null, '-0.2rem']}
        textAlign={[null, 'right']}
      >
        {stageNames[lastStageIndex]}
      </StageLabel>
    </Box>
  )
}
