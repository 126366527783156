import create from 'zustand'

export const [useSpeedBumpStore] = create((set) => ({
  isSpeedBumpVisible: false,
  href: null,

  showSpeedBump: () => set((state) => ({ ...state, isSpeedBumpVisible: true })),
  hideSpeedBump: () =>
    set((state) => ({ ...state, isSpeedBumpVisible: false, href: null })),
  setHref: (href) => set((state) => ({ ...state, href })),
}))
