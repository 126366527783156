import React from 'react'

import { Flex, Text, SVG } from 'system'
import { Anchor } from 'src/components'
import { ReactComponent as AssetIconArrowUpSVG } from 'src/assets/icon-arrow-up.svg'

export const BackToTop = ({ children = 'Back to top', ...props }) => (
  <Anchor
    href="#top"
    withExternalCheck={false}
    color="teal.40"
    outline="none"
    {...props}
  >
    <Flex alignItems="center">
      <Text
        as="span"
        mrScale="t"
        textStyle="trackedCapsWide"
        fontWeight="bold"
        fontSizeScale="t"
      >
        {children}
      </Text>
      <SVG svg={AssetIconArrowUpSVG} x={1} y={1} width="0.55rem" />
    </Flex>
  </Anchor>
)
