import React from 'react'

import { Box } from 'system'

const variants = {
  default: {
    mrScale: ['-m', 0],
    mlScale: ['-m', 0],
  },
  extendRight: {
    mrScale: ['-m', '-m'],
    mlScale: ['-m', 0],
  },
  extendLeft: {
    mrScale: ['-m', 0],
    mlScale: ['-m', '-m'],
  },
  noExtend: {
    mrScale: 0,
    mlScale: 0,
  },
}

export const Rule = ({
  color = 'gray.80',
  variant: variantName = 'default',
  ...props
}) => {
  const variant = variants[variantName]

  return (
    <Box
      as="hr"
      border="0"
      bg={color}
      height="2px"
      mrScale={variant.mrScale}
      mlScale={variant.mlScale}
      {...props}
    />
  )
}
