import React from 'react'

import { Heading } from 'system'

export const MapControlsHeading = (props) => (
  <Heading
    as="h3"
    color="green.50"
    fontFamily="sansExt"
    fontSizeScale="t-"
    fontWeight="bold"
    lineHeight="title"
    textStyle="trackedCapsVeryWide"
    {...props}
  />
)
