import React from 'react'

import { SVG } from 'system'
import { ReactComponent as AssetIslandHNLSVG } from 'src/assets/island-hnl.svg'
import { ReactComponent as AssetIslandITOSVG } from 'src/assets/island-ito.svg'
import { ReactComponent as AssetIslandKOASVG } from 'src/assets/island-koa.svg'
import { ReactComponent as AssetIslandLIHSVG } from 'src/assets/island-lih.svg'
import { ReactComponent as AssetIslandOGGSVG } from 'src/assets/island-ogg.svg'

const variants = {
  hnl: { svg: AssetIslandHNLSVG, x: 157, y: 122 },
  ito: { svg: AssetIslandITOSVG, x: 131, y: 148 },
  koa: { svg: AssetIslandKOASVG, x: 131, y: 148 },
  lih: { svg: AssetIslandLIHSVG, x: 144, y: 110 },
  ogg: { svg: AssetIslandOGGSVG, x: 153, y: 103 },
}

export const IslandSVG = ({ airportCode, ...props }) => {
  const variant = variants[airportCode?.toLowerCase?.()]

  return <SVG svg={variant?.svg} x={variant?.x} y={variant?.y} {...props} />
}
