import React from 'react'
import { linearScale } from 'styled-system-scale'

import { Box, Grid, Flex, Text, SVG, Link } from 'system'
import { ReactComponent as AssetIconDownloadSVG } from 'src/assets/icon-download.svg'
import { ReactComponent as AssetIconProjectsThickSVG } from 'src/assets/icon-projects-thick.svg'

const icons = {
  download: {
    svg: AssetIconDownloadSVG,
    x: 24,
    y: 25,
    width: linearScale('1.75rem', '2.25rem', { count: 5 }),
  },
  project: {
    svg: AssetIconProjectsThickSVG,
    x: 1,
    y: 1,
    width: linearScale('1.75rem', '2.25rem', { count: 5 }),
  },
}

export const IconLinkList = ({ children, defaultIcon, ...props }) => (
  <Text fontWeight="semibold" {...props}>
    <Grid as="ul" gridGapScale="s-" alignContent="start">
      {React.Children.map(
        children,
        (child) => child && React.cloneElement(child, { defaultIcon }),
      )}
    </Grid>
  </Text>
)

IconLinkList.Item = ({
  children,
  defaultIcon,
  icon: iconName = defaultIcon,
  href,
  ...props
}) => {
  const icon = iconName && icons[iconName]

  return (
    <Box as="li" {...props}>
      <Flex
        as={href ? Link : 'div'}
        href={href}
        alignItems="center"
        color="teal.40"
        transitionProperty="color"
        css={(p) => ({
          '&:hover, &:focus-within, &:active': {
            color: p.href ? p.theme.colors.gray[10] : 'auto',
          },
        })}
      >
        {icon && (
          <SVG
            svg={icon.svg}
            x={icon.x}
            y={icon.y}
            width={icon.width}
            mrScale="t"
            flexShrink={0}
          />
        )}
        {children}
      </Flex>
    </Box>
  )
}
