import React from 'react'

import { Heading as HeadingBase } from 'system'

export const Heading = (props) => (
  <HeadingBase
    color="gray.10"
    fontFamily="sans"
    fontSizeScale="xl"
    fontWeight="bold"
    lineHeight="title"
    {...props}
  />
)
