import React from 'react'

import { Heading } from 'system'

const variants = {
  sansExt: {
    fontFamily: 'sansExt',
    textStyle: 'trackedCapsVeryWide',
  },
  sansCond: {
    fontFamily: 'sansCond',
    textStyle: 'trackedCaps',
  },
}

export const Subheading = ({ variant: variantName = 'sansCond', ...props }) => {
  const variant = variants[variantName]

  return (
    <Heading
      as="h3"
      color="gray.50"
      fontFamily={variant.fontFamily}
      fontSizeScale="m"
      fontWeight="bold"
      lineHeight="title"
      textStyle={variant.textStyle}
      {...props}
    />
  )
}
