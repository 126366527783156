import { useStaticQuery, graphql } from 'gatsby'

export const usePrimaryNavigationData = () => {
  const queryData = useStaticQuery(graphql`
    {
      prismicNavigation(uid: { eq: "primary" }) {
        data {
          primary {
            ... on PrismicNavigationPrimaryNavItem {
              primary {
                name
                link {
                  url
                  target
                }
              }
            }
          }
        }
      }
    }
  `)

  return queryData.prismicNavigation?.data?.primary
}
