import { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { notEmpty } from 'helpers'

import { dateLikeToInt } from '../utils'

export const useProjects = ({
  airportUID,
  statusUID,
  negateStatusUID,
  statusFilters = [],
  zoneFilters = [],
  projectFilters = [],
  sortBy = 'title',
} = {}) => {
  const queryData = useStaticQuery(graphql`
    {
      allPrismicProject(sort: { fields: [data___title___text] }) {
        nodes {
          ...Project
        }
      }
    }
  `)

  return useMemo(() => {
    let projects = queryData?.allPrismicProject?.nodes

    if (airportUID)
      projects = projects?.filter?.(
        (p) => p?.data?.airport?.document?.uid === airportUID,
      )

    if (statusUID)
      projects = projects?.filter?.(
        (p) => p?.data?.status?.document?.uid === statusUID,
      )

    if (statusFilters.length)
      projects = projects.filter((p) =>
        statusFilters.includes(p?.data?.status?.document?.uid),
      )

    if (zoneFilters.length)
      projects = projects.filter((p) =>
        zoneFilters.includes(p?.data?.zone?.document?.uid),
      )

    if (projectFilters.length)
      projects = projects.filter((p) => projectFilters.includes(p?.uid))

    if (negateStatusUID)
      projects = projects?.filter?.(
        (p) => p?.data?.status?.document?.uid !== negateStatusUID,
      )

    switch (sortBy) {
      case 'title': {
        projects = [...projects].sort((a, b) => {
          const aTitle = notEmpty(a?.data?.title?.text)
          const bTitle = notEmpty(b?.data?.title?.text)

          if (!aTitle && bTitle) return 1
          if (aTitle && !bTitle) return -1

          if (aTitle > bTitle) return 1
          if (aTitle < bTitle) return -1

          return 0
        })
        break
      }

      case 'status': {
        projects = [...projects].sort((a, b) => {
          const aStatus = notEmpty(a?.data?.status?.document?.uid)
          const bStatus = notEmpty(b?.data?.status?.document?.uid)

          if (!aStatus && bStatus) return 1
          if (aStatus && !bStatus) return -1

          if (aStatus > bStatus) return 1
          if (aStatus < bStatus) return -1

          return 0
        })
        break
      }

      case 'begin': {
        projects = [...projects].sort(
          (a, b) =>
            dateLikeToInt(a?.fields?.start_date) -
            dateLikeToInt(b?.fields?.start_date),
        )
        break
      }

      case 'end': {
        projects = [...projects].sort(
          (a, b) =>
            dateLikeToInt(a?.fields?.end_date) -
            dateLikeToInt(b?.fields?.end_date),
        )
        break
      }

      case 'zone': {
        projects = [...projects].sort((a, b) => {
          const aZone = notEmpty(a?.data?.zone?.document?.uid)
          const bZone = notEmpty(b?.data?.zone?.document?.uid)

          if (!aZone && bZone) return -1
          if (aZone && !bZone) return 1

          if (aZone < bZone) return -1
          if (aZone > bZone) return 1

          return 0
        })
        break
      }

      case 'project-no':
      default: {
        projects = [...projects].sort((a, b) => {
          const aProjectId = notEmpty(a?.data?.project_number?.text)
          const bProjectId = notEmpty(b?.data?.project_number?.text)

          if (!aProjectId && bProjectId) return 1
          if (aProjectId && !bProjectId) return -1

          if (aProjectId < bProjectId) return 1
          if (aProjectId > bProjectId) return -1

          return 0
        })
      }
    }

    return projects
  }, [
    queryData,
    airportUID,
    negateStatusUID,
    projectFilters,
    sortBy,
    statusFilters,
    statusUID,
    zoneFilters,
  ])
}
