import React from 'react'

import { SVG } from 'system'
import { ReactComponent as AssetIconClearX } from 'src/assets/icon-clear-x.svg'

export const InputClearIcon = (props) => {
  return (
    <SVG
      x={1}
      y={1}
      svg={AssetIconClearX}
      width="1rem"
      color="green.50"
      transitionProperty="color"
      {...props}
    />
  )
}
