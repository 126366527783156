import React from 'react'
import { NavigationControl } from 'react-map-gl'

import { Box } from 'system'

export const MapNavigationControls = ({ setViewport }) => (
  <Box position="absolute" top={['1rem', '2rem']} right={['1rem', '2rem']}>
    <NavigationControl
      onViewportChange={(newViewport) => setViewport(newViewport)}
    />
  </Box>
)
