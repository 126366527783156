import React from 'react'
import ConditionalWrap from 'conditional-wrap'

import { Grid, Text } from 'system'
import { Subheading, NotAvailableLabel } from 'src/components'

const variants = {
  primary: {
    grid: {
      gridGapScale: 't-',
    },
    color: 'black',
    fontFamily: 'sansCond',
    fontWeight: 'bold',
    textStyle: 'trackedCaps',
    fontSizeScale: 'b',
  },
  secondary: {
    grid: {
      alignItems: 'baseline',
      gridGapScale: 't-',
      gridTemplateColumns: 'auto 1fr',
    },
    color: 'teal.40',
    fontFamily: 'sans',
    fontWeight: 'semibold',
    textStyle: undefined,
    fontSizeScale: 'b-',
  },
  tertiary: {
    grid: {
      gridGapScale: 't',
    },
    color: 'teal.40',
    fontFamily: 'sans',
    fontWeight: 'semibold',
    textStyle: undefined,
    fontSizeScale: 'b',
  },
}

export const KeyValueDetail = ({
  variant: variantName = 'primary',
  label,
  children,
  childrenIsText = true,
  ...props
}) => {
  const variant = variants[variantName]

  return (
    <Grid {...variant.grid} {...props}>
      {label && (
        <Subheading variant="sansExt" color="teal.40" fontSizeScale="xt">
          {label}
        </Subheading>
      )}
      {children ? (
        <ConditionalWrap
          condition={childrenIsText}
          wrap={(children) => (
            <Text
              color={variant.color}
              fontFamily={variant.fontFamily}
              fontSizeScale={variant.fontSizeScale}
              fontWeight={variant.fontWeight}
              textStyle={variant.textStyle}
            >
              {children}
            </Text>
          )}
        >
          {children}
        </ConditionalWrap>
      ) : (
        <NotAvailableLabel />
      )}
    </Grid>
  )
}
