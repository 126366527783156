import React, { useState } from 'react'

import { Button, SVG, Flex, Box } from 'system'
import { MapControlsBoundedBox, MapControlsHeading } from 'src/components'
import { ReactComponent as AssetIconMinusSVG } from 'src/assets/icon-minus.svg'
import { ReactComponent as AssetIconPlusSVG } from 'src/assets/icon-plus.svg'

export const MapControlsExpand = ({
  title,
  children,
  initialOpenState = false,
  withBottomExpandBorder = true,
  withBottomBorder = [true],
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(initialOpenState)

  const toggleOpen = () => setIsOpen((isOpen) => !isOpen)

  return (
    <>
      <MapControlsBoundedBox
        as={Button}
        cursor={['pointer', null, 'default']}
        onClick={toggleOpen}
        withBottomBorder={withBottomBorder}
        ptScale={['m', null, 'l']}
        pbScale={['m', null, 't']}
        {...props}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <MapControlsHeading mrScale="t">{title}</MapControlsHeading>
          <SVG
            x={1}
            y={1}
            svg={isOpen ? AssetIconMinusSVG : AssetIconPlusSVG}
            color="green.50"
            width="1rem"
            display={[null, null, 'none']}
          />
        </Flex>
      </MapControlsBoundedBox>
      <Box
        borderBottom={withBottomExpandBorder && '1px solid'}
        borderColor="black"
        display={[isOpen ? 'block' : 'none', null, 'block']}
      >
        {children}
      </Box>
    </>
  )
}
