import React from 'react'

import { useMapReducer } from '../mapReducer'

import { Button, SVG, Grid, Box } from 'system'
import { Subheading, MapControlsHeading } from 'src/components'
// import { ReactComponent as AssetIconClosuresSVG } from 'src/assets/icon-closures.svg'
import { ReactComponent as AssetIconProjectsSVG } from 'src/assets/icon-projects.svg'

const IconButton = ({ text, svg, isActive, ...props }) => (
  <Button
    color={isActive ? 'white' : 'gray.40'}
    transitionProperty="color"
    css={`
      &:hover,
      &:focus {
        color: ${(p) => p.theme.colors.white};
      }
    `}
    {...props}
  >
    <SVG
      x={1}
      y={1}
      color="inherit"
      mb=".5rem"
      mx="auto"
      width={['2rem', null, '3.6rem']}
      svg={svg}
    />
    <Subheading
      color="inherit"
      fontFamily="sans"
      fontSizeScale="t-"
      textStyle="trackedCapsVeryWide"
      textAlign="center"
    >
      {text}
    </Subheading>
  </Button>
)

export const ShowControls = ({ ...props }) => {
  const [state, dispatch] = useMapReducer()

  const filterByType = (type) =>
    dispatch({ type: 'FILTER_TYPE', payload: type })

  return (
    <Grid
      justifyContent={['space-between', 'start']}
      alignItems="center"
      gridAutoFlow="column"
      gridColumnGapScale="l"
      gridTemplateColumns={[null, null, '1fr']}
      gridTemplateRows={[null, null, 'auto auto']}
      {...props}
    >
      <MapControlsHeading
        position="relative"
        gridColumn={[null, null, '1 / -1']}
        pbScale={[0, null, 't']}
        mbScale={[0, null, 'm']}
      >
        Show
        <Box
          display={['none', null, 'block']}
          position="absolute"
          height="1px"
          bg="green.50"
          leftScale="-xl"
          rightScale="-xl"
          bottom={0}
        />
      </MapControlsHeading>
      <IconButton
        text="Projects"
        svg={AssetIconProjectsSVG}
        onClick={() => filterByType('project')}
        isActive={state.activeType === 'project'}
      />
      {/* <IconButton */}
      {/*   text="Closures" */}
      {/*   mrScale="l" */}
      {/*   svg={AssetIconClosuresSVG} */}
      {/*   onClick={() => filterByType('closure')} */}
      {/*   isActive={state.activeType === 'closure'} */}
      {/* /> */}
    </Grid>
  )
}
