import React from 'react'
import { useGesture } from 'react-use-gesture'
import { useSpring, a } from 'react-spring'

import { Box, Link, SVG } from 'system'
import { theme } from 'src/theme'
import { ReactComponent as AssetTriangleSVG } from 'src/assets/triangle.svg'

const colorSchemes = {
  light: {
    activeColor: theme.colors.gray[30],
    color: theme.colors.gray[50],
  },
  dark: {
    activeColor: theme.colors.gray[10],
    color: theme.colors.gray[10],
  },
}

export const NavLink = ({
  href,
  target,
  children,
  isActive,
  withLineHover = true,
  withWideLineHover = false,
  innerAs = Link,
  colorScheme = 'light',
  fontSizeScale = 't',
  ...props
}) => {
  const scheme = colorSchemes[colorScheme] ?? colorSchemes.light
  const [{ transform, opacity, color }, set] = useSpring(() => ({
    transform: isActive ? 'scaleX(1)' : 'scaleX(0)',
    color: isActive ? scheme.activeColor : scheme.color,
  }))

  const handleFocus = (isFocused) => {
    if (isFocused || isActive)
      set({
        opacity: 1,
        transform: 'scaleX(1)',
        color: scheme.activeColor,
      })
    else set({ opacity: 0, transform: 'scaleX(0)', color: scheme.color })
  }

  const bindGestures = useGesture({
    onHover: ({ hovering }) => handleFocus(hovering),
  })

  return (
    <Box as="li" boxStyle="lastNoMargin" {...props}>
      <Link
        as={a(innerAs)}
        style={{ color }}
        href={href}
        target={target}
        onFocus={() => handleFocus(true)}
        onBlur={() => handleFocus(false)}
        position="relative"
        outline="none"
        fontFamily="sansExt"
        fontSizeScale={fontSizeScale}
        textStyle="trackedCapsWide"
        {...bindGestures()}
      >
        {children}
        {withLineHover && (
          <Box
            as={a(Box)}
            style={{ transform, opacity }}
            position="absolute"
            top="-16px"
            left={withWideLineHover ? '-8px' : 0}
            right={withWideLineHover ? '-8px' : 0}
            height="2px"
            bg="green.50"
          />
        )}
        {isActive && withLineHover && (
          <SVG
            display={['none', 'block']}
            x={34}
            y={24}
            svg={AssetTriangleSVG}
            color="green.50"
            position="absolute"
            bottomScale={['-s', '-m']}
            mb="-4px"
            width="1.75rem"
            left="50%"
            transform="translateX(-50%)"
          />
        )}
      </Link>
    </Box>
  )
}
