import { graphql, useStaticQuery } from 'gatsby'

export const useStatuses = () => {
  const queryData = useStaticQuery(graphql`
    {
      allPrismicStatus(sort: { fields: [data___title___text] }) {
        nodes {
          ...Status
        }
      }
    }
  `)

  let statuses = queryData.allPrismicStatus.nodes

  return statuses
}
