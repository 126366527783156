import React from 'react'
import { notEmpty } from 'helpers'
import css from '@styled-system/css'

import { Grid, Flex, Button } from 'system'
import { Subheading, ProgressBar, NotAvailableLabel } from 'src/components'

import * as styles from './ProjectIntro.module.css'

const ScrollStates = {
  Init: 1,
  Hidden: 2,
  Start: 3,
  Middle: 4,
  End: 5,
}

export const ProjectIntro = ({ projectData }) => {
  const statusDoc = projectData?.status?.document

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const rScroller = React.useRef()
  const [scrollState, setScrollState] = React.useState(ScrollStates.Init)

  const statusItems = projectData?.status_labels?.filter(
    (item) => item?.status_document?.document,
  )
  const statusNameOverrides = statusItems?.map?.(
    (item) =>
      item?.status_document?.document?.data?.progress_bar_title_override?.text,
  )
  const statusNames = statusItems?.map?.(
    (item, i) =>
      statusNameOverrides[i] ||
      item?.status_document?.document?.data?.title?.text,
  )
  const statusLabels = statusItems?.map?.((item) => item?.status_label?.text)
  const stage =
    statusDoc?.data?.progress_bar_title_override?.text ||
    statusDoc?.data?.title?.text

  const onBackClick = () => {
    rScroller.current.scrollBy({ left: -240, behavior: 'smooth' })
  }
  const onForwardClick = () => {
    rScroller.current.scrollBy({ left: 240, behavior: 'smooth' })
  }

  // Initially check if the progress bar is scrollable. If it is, show our
  // scroll controls. Also, re-run this check whenever the window is resized.
  React.useEffect(() => {
    const scroller = rScroller.current
    if (!scroller) return

    const checkIfScrollable = () => {
      if (scroller.scrollWidth <= scroller.clientWidth) {
        setScrollState(ScrollStates.Hidden)
      } else {
        setScrollState(ScrollStates.Start)
      }
    }

    if (scrollState === ScrollStates.Init) {
      checkIfScrollable()
    }

    window.addEventListener('resize', checkIfScrollable, { passive: true })

    return () => window.removeEventListener('resize', checkIfScrollable)
  }, [scrollState])

  // Whenever scrolling occurrs, check if we are at the start or end and update
  // the state accordingly.
  React.useEffect(() => {
    const scroller = rScroller.current
    if (!scroller) return

    /** @type {(e: Event) => void} */
    const onScroll = (e) => {
      /** @type {HTMLDivElement} */
      const target = e.target

      if (target.scrollLeft <= 1) {
        console.log('at start')
        setScrollState(ScrollStates.Start)
      } else if (
        target.scrollLeft + target.clientWidth >=
        target.scrollWidth - 1
      ) {
        console.log('at end')
        setScrollState(ScrollStates.End)
      } else if (scrollState !== ScrollStates.Middle) {
        setScrollState(ScrollStates.Middle)
      }
    }

    scroller.addEventListener('scroll', onScroll, { passive: true })

    return () => scroller.removeEventListener('scroll', onScroll)
  }, [scrollState])

  return (
    <Grid pyScale="l" gridGapScale={['m', 's']}>
      <Flex>
        <Subheading color="black" lineHeight="solid">
          Progress
        </Subheading>

        {(scrollState === ScrollStates.Start ||
          scrollState === ScrollStates.Middle ||
          scrollState === ScrollStates.End) && (
          <Flex
            display={['none', 'flex']}
            alignItems="center"
            ml={3}
            className={styles.scrollers}
          >
            <Button
              width="1.25rem"
              height="1.25rem"
              transitionProperty="color"
              disabled={scrollState === ScrollStates.Start}
              css={css({
                '&:hover, &:focus': { color: 'green.50' },
                '&:disabled': { opacity: 0.5, pointerEvents: 'none' },
              })}
              aria-label="Scroll progress back"
              onClick={onBackClick}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m12 19-7-7 7-7" />
                <path d="M19 12H5" />
              </svg>
            </Button>
            <Button
              width="1.25rem"
              height="1.25rem"
              transitionProperty="color"
              disabled={scrollState === ScrollStates.End}
              css={css({
                '&:hover, &:focus': { color: 'green.50' },
                '&:disabled': { opacity: 0.5, pointerEvents: 'none' },
              })}
              aria-label="Scroll progress forwards"
              onClick={onForwardClick}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M5 12h14" />
                <path d="m12 5 7 7-7 7" />
              </svg>
            </Button>
          </Flex>
        )}
      </Flex>

      {notEmpty(statusNames) ? (
        <ProgressBar
          stageNames={statusNames}
          stageDescriptions={statusLabels}
          stage={stage}
          stagePercentCompleted={Number.parseInt(
            projectData?.status_percent_completed,
          )}
          ref={rScroller}
        />
      ) : (
        <NotAvailableLabel />
      )}
    </Grid>
  )
}
