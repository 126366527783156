import React from 'react'

import { Grid, Flex } from 'system'
import { Subheading, ArrowButton } from 'src/components'

export const Pagination = ({
  page,
  totalPages,
  incPage,
  decPage,
  hasNextPage,
  hasPreviousPage,
  ...props
}) => (
  <Grid
    gridAutoFlow="column"
    display="inline-grid"
    alignItems="center"
    color="teal.40"
    gridGapScale={['t', 's']}
    {...props}
  >
    <ArrowButton
      direction="left"
      onClick={() => decPage()}
      disabled={!hasPreviousPage}
    />
    <Flex alignItems="baseline">
      <Subheading as="span" fontSizeScale="b" color="inherit">
        {page}
      </Subheading>
      <Subheading
        as="span"
        fontSizeScale={['s', 'b-']}
        color="inherit"
        mxScale="t-"
      >
        of
      </Subheading>
      <Subheading as="span" fontSizeScale="b" color="inherit">
        {totalPages}
      </Subheading>
    </Flex>
    <ArrowButton
      direction="right"
      onClick={() => incPage()}
      disabled={!hasNextPage}
    />
  </Grid>
)
