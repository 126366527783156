import React from 'react'
import { times } from 'tiny-compose-fns'

import { useAirports } from 'src/hooks'
import { useMapReducer } from '../mapReducer'

import { Grid, Box, Button } from 'system'
import { Subheading } from 'src/components'

const AirportButton = ({ code, city, island, isActive, ...props }) => (
  <Button
    color={isActive ? 'white' : 'gray.40'}
    textAlign="center"
    pyScale="m"
    bg="gray.10"
    transitionProperty="color"
    fontFamily="sans"
    css={`
      &:hover,
      &:focus {
        color: ${(p) => p.theme.colors.white};
      }
    `}
    {...props}
  >
    <Subheading
      color="inherit"
      fontSizeScale="l"
      mbScale="t-"
      fontWeight="bold"
      fontFamily="inherit"
    >
      {code}
    </Subheading>
    <Subheading
      color="inherit"
      fontSizeScale="t-"
      fontWeight="bold"
      fontFamily="inherit"
      textStyle="trackedCapsVeryWide"
    >
      <Box>{city}</Box>
      <Box>{island}</Box>
    </Subheading>
  </Button>
)

export const AirportControls = ({ airportsOrder }) => {
  const [state, dispatch] = useMapReducer()

  const airports = useAirports()
  const sortedAirports = airports.sort(
    (a, b) =>
      airportsOrder.findIndex((uid) => uid === a.uid) -
      airportsOrder.findIndex((uid) => uid === b.uid),
  )

  const activateAirport = (airportUid) =>
    dispatch({ type: 'FILTER_AIRPORT', payload: airportUid })

  return (
    <Grid gridTemplateColumns="repeat(3, 1fr)" gridGap="1px" bg="black">
      {sortedAirports.map((airport) => (
        <AirportButton
          key={airport.uid}
          code={airport.data.code?.text}
          city={airport.data.city?.text}
          island={airport.data.island?.text}
          isActive={state.activeAirport === airport.uid}
          onClick={() => activateAirport(airport.uid)}
        />
      ))}

      {times(
        (i) => (
          // We render placeholder nodes to fill in the gaps for proper borders
          <Box bg="gray.10" key={i} />
        ),
        3 - (airports.length % 3),
      )}
    </Grid>
  )
}
