import React from 'react'
import { propPairsEq, getRichText, notEmpty } from 'helpers'

import { Text } from 'system'
import { BoundedBox } from 'src/components'

export const PageBodyLastUpdated = (props) => {
  if (!props.date) return null

  return (
    <BoundedBox
      as="section"
      bg="teal.90"
      pyScale="m"
      maxWidth="none"
      {...props}
    >
      <Text fontStyle="italic" fontSizeScale="s">
        Page last updated: {props.date}
      </Text>
    </BoundedBox>
  )
}

PageBodyLastUpdated.mapDataToProps = ({ data, context, nextContext }) => {
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    date: data.date,
  }
}

PageBodyLastUpdated.mapDataToContext = () => ({
  bg: 'teal.90',
})
