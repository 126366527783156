import { graphql, useStaticQuery } from 'gatsby'

export const useZones = () => {
  const queryData = useStaticQuery(graphql`
    {
      allPrismicZone(sort: { fields: [data___name___text] }) {
        nodes {
          ...Zone
        }
      }
    }
  `)

  return queryData.allPrismicZone.nodes
}
