import React, { useRef, useEffect, useCallback, useState } from 'react'
import Supercluster from 'supercluster'

import { useMapReducer } from '../mapReducer'

import { MapMarker } from './MapMarker'

const deriveTypeForPoint = (point) => {
  if (point.properties.cluster) return 'cluster'
  return point.properties?.marker?.type
}

export const MapMarkers = () => {
  const [state] = useMapReducer()
  const [points, setPoints] = useState([])
  const index = useRef(new Supercluster())

  const updateClusters = useCallback(() => {
    const zoom = state.map.getZoom()
    const bounds = state.map.getBounds()
    const bbox = [
      bounds.getWest(),
      bounds.getSouth(),
      bounds.getEast(),
      bounds.getNorth(),
    ]

    setPoints(index.current.getClusters(bbox, Math.floor(zoom)))
  }, [state.map])

  /**
   * Update the index when filteredMarkers changes.
   */
  useEffect(() => {
    if (state.state !== 'INITIALIZING') {
      const features = state.filteredMarkers.map((marker) => ({
        type: 'Feature',
        geometry: marker.point,
        properties: { marker },
      }))

      index.current.load(features)

      updateClusters()
    }
  }, [state.state, state.filteredMarkers, updateClusters])

  /**
   * Update the clusters on map changes.
   */
  useEffect(() => {
    if (state.state === 'INITIALIZING') return

    state.map.on('zoom', updateClusters)
    state.map.on('idle', updateClusters)
  }, [state.state, state.map, state.filteredMarkers, updateClusters])

  return points.map((point) => {
    const [longitude, latitude] = point.geometry.coordinates

    const type = deriveTypeForPoint(point)
    const id = point.id ?? point.properties.marker.uid
    const title = point.properties?.marker?.title
    const polygon = point?.properties?.marker?.polygon

    const features =
      type === 'cluster'
        ? index.current.getLeaves(point.id, Infinity)
        : undefined

    return (
      <MapMarker
        key={id}
        id={id}
        longitude={longitude}
        latitude={latitude}
        type={type}
        polygon={polygon}
        title={title}
        feature={point}
        features={features}
      />
    )
  })
}
