import React from 'react'
import { linearScale } from 'styled-system-scale'

import { usePrimaryNavigationData, useSettingsData } from 'src/hooks'

import { Box, Flex, Text } from 'system'
import {
  NavLink,
  Logo,
  BoxButton,
  BoundedBox,
  Anchor,
  SpeedBumpLink,
} from 'src/components'

const MiscCallout = ({ children, ...props }) => (
  <Text
    textStyle="trackedCaps"
    color="gray.50"
    fontWeight="semibold"
    pyScale="s"
    mxScale="-m"
    pxScale="m"
    fontSizeScale="t-"
    {...props}
  >
    <Text maxWidth={['35ch', 'none']}>{children}</Text>
  </Text>
)

export const PageBodyFooter = ({ ...props }) => {
  const navData = usePrimaryNavigationData()
  const settingsData = useSettingsData()

  return (
    <BoundedBox as="footer" ptScale="l" pbScale={0} maxWidth="none" {...props}>
      <Flex
        alignItems={['stretch', null, 'flex-start']}
        flexDirection={['column', null, 'row']}
        justifyContent={[null, null, 'space-between']}
        mbScale={[0, null, 'l']}
      >
        <Logo
          width={linearScale('15.5rem', '21.25rem', { count: 5 })}
          maxWidth="100%"
          mbScale={['xl', null, 0]}
        />

        <Flex flexDirection={['column', null, 'row']}>
          <Text
            as="nav"
            lineHeight="solid"
            textStyle="trackedCaps"
            fontSizeScale="s"
            fontWeight="bold"
            color="gray.50"
            mbScale={['xxl', null, 0]}
            mrScale={[0, null, 'xl']}
          >
            <Box as="ul">
              {navData.map((item) => (
                <NavLink
                  key={item?.primary?.name}
                  href={item?.primary?.link?.url}
                  target={item?.primary?.link?.target}
                  mbScale={['t', null, 's']}
                  boxStyle="lastNoMargin"
                  withLineHover={false}
                >
                  {item?.primary?.name}
                </NavLink>
              ))}
            </Box>
          </Text>

          <Box
            bg={['teal.70', null, 'transparent']}
            mxScale={['-m', null, 0]}
            pScale={['m', null, 0]}
            color={['black', null, 'teal.60']}
          >
            <Text
              mbScale="t"
              lineHeight="solid"
              fontSizeScale="b-"
              plScale={[0, 't-']}
            >
              More airport info at
            </Text>
            <BoxButton
              as={SpeedBumpLink}
              href={settingsData?.dota_website_link?.url}
              target="_blank"
              colorScheme="dark"
              display={['flex', null, 'none']}
              mxScale="-t"
            >
              DOT Airports
            </BoxButton>
            <BoxButton
              as={SpeedBumpLink}
              href={settingsData?.dota_website_link?.url}
              target="_blank"
              display={['none', null, 'flex']}
            >
              DOT Airports
            </BoxButton>
          </Box>
        </Flex>
      </Flex>

      <Flex
        flexDirection={['column', null, 'row']}
        justifyContent={[null, null, 'space-between']}
        borderTop={[null, null, '1px solid']}
        borderColor={[null, null, 'gray.80']}
        mxScale={[0, null, '-m']}
        pxScale={[0, null, 'm']}
      >
        {settingsData && (
          <MiscCallout
            mrScale={['-m', null, 's']}
            borderBottom={['1px solid', null, 0]}
            borderColor="gray.80"
          >
            {settingsData.site_copyright.text}
          </MiscCallout>
        )}
        <MiscCallout>
          Site design & development by{' '}
          <Anchor href="https://walltowall.com" target="_blank">
            Wall-to-Wall Studios
          </Anchor>
        </MiscCallout>
      </Flex>
    </BoundedBox>
  )
}
