import { useState, useEffect } from 'react'
import debounce from 'lodash.debounce'

import { checkIsMobile } from 'src/utils'

export const useIsMobile = () => {
  const [isOnMobile, setIsOnMobile] = useState(() => checkIsMobile())

  useEffect(() => {
    const checkForMobile = debounce(() => setIsOnMobile(checkIsMobile()), 500)

    window.addEventListener('resize', checkForMobile)
    return () => window.removeEventListener('resize', checkForMobile)
  }, [])

  return isOnMobile
}
