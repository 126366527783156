import React from 'react'
import { Text, Grid } from 'system'

import { Subheading } from 'src/components'
import * as styles from './StatusList.module.css'

export const StatusList = ({ withBorder = true, statuses = [], ...props }) => {
  return (
    <Grid
      gridTemplateColumns="1fr auto"
      alignItems="center"
      borderTop={withBorder ? '1px solid' : undefined}
      borderColor="gray.80"
      {...props}
    >
      {statuses.map((l, idx) => (
        <React.Fragment key={l.title}>
          <Text
            as="p"
            fontSizeScale="t+"
            color="gray.10"
            fontWeight="semibold"
            pb="6px"
            pt={withBorder ? (idx === 0 ? '14px' : '6px') : 0}
            pr={3}
            borderRight={withBorder ? '1px solid' : undefined}
            borderColor="gray.80"
            lineHeight={1}
          >
            {l.title}
          </Text>

          <Subheading
            as="p"
            variant="sansCond"
            fontSizeScale="t"
            color="teal.50"
            pl={3}
            pb="6px"
            pt={withBorder ? (idx === 0 ? '14px' : '6px') : 0}
            className={styles.date}
            lineHeight={1}
          >
            {l.date}
          </Subheading>
        </React.Fragment>
      ))}
    </Grid>
  )
}
