import React, { useCallback, useMemo, useRef } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from 'hooks'

import { Box, Text, Input } from 'system'
import { OffsetBoundedBox } from 'src/components'

export const PageBodySearchBar = (props) => {
  const location = useLocation()
  const inputRef = useRef()

  const query = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)

    return searchParams.get('query')
  }, [location.search])

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault()

      const searchParams = new URLSearchParams(location.search)
      const query = inputRef.current.value.trim()

      if (query) searchParams.set('query', query)
      else searchParams.delete('query')

      navigate(
        [window.location.pathname, searchParams.toString()]
          .filter(Boolean)
          .join('?'),
      )
    },
    [location.search],
  )

  return (
    <OffsetBoundedBox as="section" bg="teal.90" {...props}>
      <Box as="form" onSubmit={onSubmit}>
        <Box as="label">
          <Text as="span">Search</Text>
          <Input ref={inputRef} name="query" defaultValue={query} />
        </Box>
      </Box>
    </OffsetBoundedBox>
  )
}

PageBodySearchBar.mapDataToContext = () => ({
  bg: 'teal.90',
})
