import React, { useState } from 'react'
import { upperFirst } from 'tiny-compose-fns/noFp'
import { isInternal } from 'helpers'

import { Text, Box, SVG, Button, Flex, Link } from 'system'

import { StatusList } from 'src/components'

import { ReactComponent as AssetIconCloseSVG } from 'src/assets/icon-close.svg'
import { ReactComponent as AssetIconArrowRightSVG } from 'src/assets/icon-arrow-right.svg'
import { ReactComponent as AssetIconArrowLeftSVG } from 'src/assets/icon-arrow-left.svg'
import { ReactComponent as AssetIconClosuresSVG } from 'src/assets/icon-closures.svg'
import { ReactComponent as AssetIconProjectsSVG } from 'src/assets/icon-projects.svg'

const typeIcons = {
  closure: { svg: AssetIconClosuresSVG, x: 1, y: 1 },
  project: { svg: AssetIconProjectsSVG, x: 1, y: 1 },
}

const CloseButton = (props) => (
  <Button
    transitionProperty="color"
    {...props}
    css={(p) => ({ '&:hover, &:focus': { color: p.theme.colors.teal[40] } })}
  >
    <SVG x={1} y={1} svg={AssetIconCloseSVG} width={['0.625rem', '1rem']} />
  </Button>
)

const PopupShell = ({
  children,
  name,
  href,
  type,
  icon: iconName,
  onBackClick,
  close,
  ...props
}) => {
  const icon = iconName && typeIcons[iconName]
  const internal = isInternal(href)

  return (
    <Box
      bg="white"
      borderRadius="16px"
      boxShadow="0 1px 2px rgba(0, 0, 0, 0.3), 0 4px 6px rgba(0, 0, 0, 0.2)"
      cursor="default"
      maxWidth={['20rem', '25rem', null, '28rem']}
      position="relative"
      overflow="hidden"
      zIndex="10"
      {...props}
    >
      <Flex
        alignItems="flex-start"
        ptScale={['t', null, 's+']}
        pbScale={[icon ? 's+' : 't', null, 's+']}
        plScale={icon ? ['t', null, null, 'xl'] : 'm'}
        prScale={'t'}
      >
        {icon && (
          <SVG
            svg={icon.svg}
            x={icon.x}
            y={icon.y}
            width={['2.25rem', null, '2.75rem']}
            position={['relative', null, null, 'absolute']}
            mrScale="t"
            topScale={[0, null, null, 't']}
            leftScale={[0, null, null, 't']}
            flex="0 0 auto"
          />
        )}
        <Text
          fontWeight="semibold"
          fontSizeScale={['s', 'b']}
          flexGrow="1"
          lineHeight="title"
        >
          {name}
        </Text>
        <CloseButton
          mlScale="t"
          pScale="s-"
          mtScale={['-t', null, '-s+']}
          mbScale={[icon ? '-m' : '-t', null, '-s+']}
          mrScale="-t"
          onClick={close}
        />
      </Flex>
      <Box mbScale={['t', 0]}>{children}</Box>
      {(onBackClick || href) && (
        <Flex bg="gray.10" justifyContent="flex-end">
          {onBackClick && (
            <Button
              onClick={onBackClick}
              display="block"
              pyScale={['t', 's+']}
              pxScale={['s-', 's+']}
              transitionProperty="background-color, color"
              outline="0"
              flexGrow="1"
              color="white"
              css={(p) => ({
                '&:hover, &:focus': {
                  backgroundColor: p.theme.colors.teal[40],
                  color: p.theme.colors.white,
                },
              })}
            >
              <Flex alignItems="center">
                <SVG
                  svg={AssetIconArrowLeftSVG}
                  x={19}
                  y={11}
                  width="1.35rem"
                  mrScale="t"
                />
                <Text
                  fontFamily="sansExt"
                  fontSizeScale={['t-', null, 'xt']}
                  textStyle="trackedCapsWide"
                  display={['none', 'block']}
                >
                  Back
                </Text>
              </Flex>
            </Button>
          )}
          {href && (
            <Button
              as={Link}
              href={href}
              target={internal ? undefined : '_blank'}
              rel={internal ? undefined : 'noopener nofollow noreferrer'}
              bg="green.50"
              display="block"
              pyScale={['t', 's+']}
              pxScale={['s-', 's+']}
              transitionProperty="background-color, color"
              outline="0"
              flexGrow={['1', '0']}
              css={(p) => ({
                '&:hover, &:focus': {
                  backgroundColor: p.theme.colors.teal[40],
                  color: p.theme.colors.white,
                },
              })}
            >
              <Flex justifyContent="flex-end" alignItems="center">
                <Text
                  mrScale="t"
                  fontFamily="sansExt"
                  fontSizeScale={['t-', null, 'xt']}
                  textStyle="trackedCapsWide"
                >
                  {upperFirst(type)} Info
                </Text>
                <SVG
                  svg={AssetIconArrowRightSVG}
                  x={19}
                  y={11}
                  width="1.35rem"
                />
              </Flex>
            </Button>
          )}
        </Flex>
      )}
    </Box>
  )
}

export const FeaturePopup = ({ onBackClick, feature, close }) => {
  const marker = feature?.properties?.marker

  return (
    <PopupShell
      onBackClick={onBackClick}
      href={marker?.externalUrl || marker?.url}
      name={marker?.title}
      icon={marker?.type}
      type={marker?.type}
      close={close}
    >
      <StatusList
        statuses={marker.statusLabels}
        mlScale={[0, 'm', null, 'xl']}
        pbScale={[0, 's']}
        plScale={['m', 0]}
        prScale={['m', 's']}
      />
    </PopupShell>
  )
}

export const FeatureListPopup = ({ features = [], type, close }) => {
  const [activeFeatureIndex, setActiveFeatureIndex] = useState()
  const activeFeature = features[activeFeatureIndex]

  const icon = type && typeIcons[type]

  if (activeFeature)
    return (
      <FeaturePopup
        feature={activeFeature}
        close={close}
        onBackClick={() => setActiveFeatureIndex()}
      />
    )

  return (
    <PopupShell name={`Select ${upperFirst(type)}`} close={close}>
      <Box
        as="ul"
        maxHeight={['10rem', '15rem']}
        overflowY="auto"
        borderTop="1px solid"
        borderColor="gray.80"
        css={{
          overscrollBehavior: 'contain',
          WebkitOverflowScrolling: 'touch',
        }}
      >
        {features.map((feature, i) => {
          const marker = feature?.properties?.marker

          return (
            <Box
              key={marker.uid}
              as="li"
              borderTop={i === 0 ? '0' : '1px solid'}
              borderColor="gray.80"
            >
              <Button
                width="100%"
                onClick={() => setActiveFeatureIndex(i)}
                pyScale={['t', null, 's-']}
                plScale="t"
                prScale="t"
                transitionProperty="background-color"
                css={(p) => ({
                  '&:hover, &:focus': {
                    backgroundColor: p.theme.colors.gray[90],
                  },
                })}
              >
                <Flex alignItems="flex-start">
                  {icon && (
                    <SVG
                      svg={icon.svg}
                      x={icon.x}
                      y={icon.y}
                      width={['1.6rem', '2rem']}
                      mrScale="t"
                      flex="0 0 auto"
                    />
                  )}
                  <Box flexGrow="1">
                    <Text
                      fontWeight="semibold"
                      fontSizeScale={['s', 'b']}
                      lineHeight="title"
                    >
                      {marker?.title}
                    </Text>
                    {(marker?.startDate || marker?.endDate) && (
                      <Text fontSizeScale={['s', null, 'b-']} mtScale="t-">
                        <Text display="inline">
                          {marker?.startDate || (
                            <Text display="inline" color="teal.50">
                              N/A
                            </Text>
                          )}
                        </Text>
                        <Text display="inline-block" mxScale="t-">
                          &mdash;
                        </Text>
                        <Text display="inline">
                          {marker?.endDate || (
                            <Text display="inline" color="teal.50">
                              N/A
                            </Text>
                          )}
                        </Text>
                      </Text>
                    )}
                  </Box>
                </Flex>
              </Button>
            </Box>
          )
        })}
      </Box>
    </PopupShell>
  )
}
