import React from 'react'
import shallow from 'zustand/shallow'
import { isInternal } from 'helpers'

import { Link } from 'system'

import { useSpeedBumpStore } from 'src/hooks'

export const SpeedBumpLink = ({ href, withExternalCheck = true, ...props }) => {
  const { setHref, showSpeedBump } = useSpeedBumpStore(
    (store) => ({
      setHref: store.setHref,
      showSpeedBump: store.showSpeedBump,
    }),
    shallow,
  )

  const handleClick = (event, href) => {
    if (isInternal(href) || !withExternalCheck) return

    event.preventDefault()
    setHref(href)
    showSpeedBump()
  }

  return (
    <Link
      href={href}
      onClick={(event) => handleClick(event, href)}
      {...props}
    />
  )
}
