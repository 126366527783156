import React from 'react'
import { linearScale } from 'styled-system-scale'

import { Flex, Button as ButtonBase, SVG, AspectRatio, Text } from 'system'
import { ReactComponent as AssetIconRightSVG } from 'src/assets/icon-arrow-right.svg'

export const Button = ({ children, withArrowIcon = true, ...props }) => (
  <Flex
    alignItems="center"
    as={ButtonBase}
    bg="green.50"
    borderRadius="10em"
    color="gray.10"
    fontFamily="sansExt"
    fontSizeScale="xt"
    fontWeight="bold"
    lineHeight="solid"
    plScale={withArrowIcon ? 's' : 's-'}
    prScale={withArrowIcon ? 't-' : 't'}
    pyScale={withArrowIcon ? 't-' : 't'}
    textStyle="trackedCapsWide"
    transitionProperty="background-color, color"
    minWidth={
      withArrowIcon ? linearScale('10rem', '12rem', { count: 5 }) : undefined
    }
    css={(p) => ({
      '&:hover, &:focus': {
        backgroundColor: p.theme.colors.teal[40],
        color: p.theme.colors.white,
      },
    })}
    {...props}
  >
    <Text flexGrow="1" css={{ whiteSpace: 'nowrap' }}>
      {children}
    </Text>
    {withArrowIcon && (
      <AspectRatio
        x={1}
        y={1}
        border="1px solid"
        borderRadius="50%"
        flexShrink="0"
        mlScale="t"
        width={['2rem', '2.25rem']}
      >
        <Flex alignItems="center" height="100%" justifyContent="center">
          <SVG svg={AssetIconRightSVG} x={19} y={11} width="60%" />
        </Flex>
      </AspectRatio>
    )}
  </Flex>
)
