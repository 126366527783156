import React, { useState, useRef } from 'react'
import { graphql } from 'gatsby'
import { camelCase } from 'tiny-compose-fns'
import { propPairsEq, notEmpty } from 'helpers'

import { useClosures } from 'src/hooks'

import { Box, Grid, Text, Flex } from 'system'
import {
  AirportCircle,
  Heading,
  IslandSVG,
  OffsetBoundedBox,
  Subheading,
  BackToTop,
  SortSelect,
  ListTable,
  Tabs,
} from 'src/components'

const FilterInput = ({ value, onChange, ...props }) => (
  <Flex
    as="label"
    justifyContent={[null, 'flex-end']}
    alignItems="baseline"
    {...props}
  >
    <Text
      as="span"
      color="gray.50"
      textStyle="trackedCapsWide"
      fontWeight="bold"
      fontSizeScale="t"
      lineHeight="solid"
      mr="6px"
    >
      Sort by
    </Text>
    <SortSelect
      value={value}
      onChange={onChange}
      options={{
        name: 'Name',
        begin: 'Begin Date',
        end: 'End Date',
      }}
    />
  </Flex>
)

export const PageBodyAirportClosuresList = ({
  displaySide = 'left',
  airportUID,
  airportCode,
  airportName,
  airportLocation,
  ...props
}) => {
  const ref = useRef()
  const [sortBy, setSortBy] = useState('title')

  const currentClosures = useClosures({
    airportUID,
    negateStatusUID: 'completed',
    sortBy,
  })

  const handleFilterChange = (event) => setSortBy(event.target.value)

  const displaySideIsLeft = displaySide === 'left'
  const ruleVariant = displaySideIsLeft ? undefined : 'extendLeft'

  return (
    <OffsetBoundedBox
      ref={ref}
      id={airportUID}
      as="section"
      bg="teal.90"
      innerProps={{ ptScale: ['m', 'l'] }}
      offsetSide={displaySide}
      {...props}
    >
      <Grid
        gridRowGapScale="m"
        gridColumnGapScale="s"
        gridTemplateColumns={['repeat(4, 1fr)', 'repeat(8, 1fr)']}
        mbScale="l"
      >
        <Grid
          gridColumn="1 / -1"
          alignItems="center"
          gridGapScale="m"
          gridAutoFlow="column"
          gridTemplateColumns="auto 1fr"
        >
          <AirportCircle>{airportCode}</AirportCircle>
          <Box>
            <Heading
              as="h3"
              color="teal.60"
              fontSizeScale={['m+', 'l']}
              mbScale="t-"
              mrScale={[0, 'm']}
            >
              {airportName}
            </Heading>
            <Subheading color="gray.50" css={{ whiteSpace: 'nowrap' }}>
              {airportLocation}
            </Subheading>
          </Box>
          <IslandSVG
            display={['none', null, 'block']}
            airportCode={airportCode}
            width="8rem"
            justifySelf="end"
          />
        </Grid>
      </Grid>

      <Tabs
        renderAccessories={() => (
          <Box myScale={['t', 0]} prScale={[0, displaySideIsLeft ? 'm' : 0]}>
            <FilterInput value={sortBy} onChange={handleFilterChange} />
          </Box>
        )}
        ruleVariant={ruleVariant}
        mrScale={[0, displaySideIsLeft ? '-m' : 0]}
      >
        <Tabs.Tab key="current" name="Current Closures" shortName="Current">
          <ListTable
            containerRef={ref}
            emptyMessage="No closures at this time"
            paramKey={`current-${airportUID}`}
            list={currentClosures}
            renderHeadings={() => (
              <>
                <ListTable.Heading>Type</ListTable.Heading>
                <ListTable.Heading>Begins</ListTable.Heading>
                <ListTable.Heading>Ends</ListTable.Heading>
                <ListTable.Heading>Hours</ListTable.Heading>
              </>
            )}
            renderItem={(closure) => (
              <ListTable.Item
                name={closure?.data?.title?.text}
                href={closure?.url}
              >
                <ListTable.ItemData label="Type">
                  {notEmpty(closure?.data?.type?.document?.data?.title?.text)}
                </ListTable.ItemData>
                <ListTable.ItemData label="Begins">
                  {notEmpty(closure?.data?.start_date?.text)}
                </ListTable.ItemData>
                <ListTable.ItemData label="Ends">
                  {notEmpty(closure?.data?.end_date?.text)}
                </ListTable.ItemData>
                <ListTable.ItemData label="Hours">
                  {notEmpty(closure?.data?.hours?.text)}
                </ListTable.ItemData>
              </ListTable.Item>
            )}
            renderFooterAccessories={() => (
              <Box
                myScale={['t', 0]}
                prScale={[0, displaySideIsLeft ? 'm' : 0]}
              >
                <BackToTop />
              </Box>
            )}
            ruleVariant={ruleVariant}
            mtScale={[0, null, null, 's+']}
          />
        </Tabs.Tab>
      </Tabs>
    </OffsetBoundedBox>
  )
}

PageBodyAirportClosuresList.mapDataToProps = ({
  data,
  context,
  nextContext,
  previousContext,
}) => {
  const airportDoc = data?.primary?.airport?.document

  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    previousSharesBg: propPairsEq('bg', context, previousContext),
    displaySide: notEmpty(camelCase(data?.primary?.display_side)),
    airportUID: airportDoc?.uid,
    airportCode: airportDoc?.data?.code?.text,
    airportName: airportDoc?.data?.full_name?.text,
    airportLocation: airportDoc?.data?.location?.text,
  }
}

PageBodyAirportClosuresList.mapDataToContext = () => ({
  bg: 'teal.90',
})

export const fragment = graphql`
  fragment PageBodyAirportClosuresList on Query {
    prismicPage(uid: { eq: $uid }) {
      data {
        body {
          ... on PrismicPageBodyAirportClosuresList {
            id
            primary {
              display_side
              airport {
                document {
                  ...Airport
                }
              }
            }
          }
        }
      }
    }
  }
`
