import React from 'react'
import { graphql } from 'gatsby'
import { propPairsEq, getRichText, notEmpty } from 'helpers'

import {
  Box,
  Link,
  Text,
  Flex,
  Image,
  ImageContainer,
  AspectRatio,
} from 'system'
import {
  HTMLContent,
  OffsetBoundedBox,
  Button,
  Subheading,
  PillButton,
  SpeedBumpLink,
} from 'src/components'

const ListItem = ({
  heading,
  textHTML,
  buttonLink,
  buttonTarget,
  buttonText = 'Read More',
  index,
  ...props
}) => {
  return (
    <Box
      as="li"
      borderTop="2px solid"
      borderColor="gray.80"
      ptScale="l"
      mbScale="l"
      boxStyle="lastNoMargin"
      {...props}
    >
      <Flex alignItems="center" mbScale="m">
        {index && (
          <Flex
            as={Text}
            fontWeight="bold"
            width="1rem"
            height="1rem"
            justifyContent="center"
            alignItems="center"
            bg="gray.80"
            pScale="s"
            borderRadius="50%"
            mrScale="t"
          >
            <Text as="span" fontWeight="medium" fontFamily="sansExt">
              {index}
            </Text>
          </Flex>
        )}
        {heading && <Subheading color="gray.10">{heading}</Subheading>}
      </Flex>
      {textHTML && (
        <HTMLContent html={textHTML} mbScale="s" boxStyle="lastNoMargin" />
      )}
      {buttonLink && (
        <PillButton
          as={SpeedBumpLink}
          href={buttonLink}
          target={buttonTarget}
          display="inline-flex"
        >
          {buttonText}
        </PillButton>
      )}
    </Box>
  )
}

export const PageBodyContentWithImage = ({
  buttonText = 'Read More',
  buttonLink,
  buttonTarget,
  imageFluid,
  imageAlt,
  introHTML,
  textHTML,
  listHeadingHTML,
  listItems,
  ...props
}) => {
  return (
    <OffsetBoundedBox
      as="section"
      offsetSide="left"
      bg="teal.90"
      innerProps={{ prScale: ['m', null, null, 0] }}
      {...props}
    >
      <Flex>
        <Box
          mrScale={[0, null, null, 'xxl']}
          width={[null, null, null, 2 / 3, 3 / 4]}
        >
          {introHTML && (
            <HTMLContent
              html={introHTML}
              borderBottom="2px solid"
              borderColor="green.50"
              pbScale="l"
              mbScale="m"
            />
          )}

          {textHTML && (
            <HTMLContent html={textHTML} mbScale="m" boxStyle="lastNoMargin" />
          )}
          {buttonLink && (
            <Button
              as={Link}
              href={buttonLink}
              target={buttonTarget}
              width="min-content"
            >
              {buttonText}
            </Button>
          )}
          {listHeadingHTML && (
            <HTMLContent
              mtScale="l"
              html={listHeadingHTML}
              mbScale="s"
              componentOverrides={{
                h1: (Comp) => (props) => (
                  <Comp {...props} fontSizeScale="l+" color="teal.40" />
                ),
              }}
            />
          )}
          {listItems && (
            <Box as="ul">
              {listItems.map((item, i) => (
                <ListItem
                  key={item?.heading}
                  heading={item?.heading}
                  textHTML={item?.textHTML}
                  buttonLink={item?.buttonLink}
                  buttonTarget={item?.buttonTarget}
                  buttonText={item?.buttonText}
                  index={i + 1}
                />
              ))}
            </Box>
          )}
        </Box>
        {imageFluid && (
          <ImageContainer
            display={['none', null, null, 'block']}
            width={[
              null,
              null,
              null,
              'calc(33% + (100vw - 100%) / 2)',
              'calc(25% + (100vw - 100%) / 2)',
            ]}
            mr="calc(-1 * (100vw - 100%) / 2)"
            flexShrink={0}
          >
            <AspectRatio x={4} y={3}>
              <Image fluid={imageFluid} alt={imageAlt} />
            </AspectRatio>
          </ImageContainer>
        )}
      </Flex>
    </OffsetBoundedBox>
  )
}

PageBodyContentWithImage.mapDataToProps = ({
  data,
  context,
  nextContext,
  previousContext,
}) => {
  const primary = data?.primary
  const items = data?.items

  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    previousSharesBg: propPairsEq('bg', context, previousContext),
    buttonLink: primary?.button_link?.url,
    buttonTarget: primary?.button_link?.target,
    buttonText: notEmpty(primary?.button_text?.text),
    imageAlt: primary?.image?.alt,
    imageFluid: primary?.image?.fluid,
    introHTML: getRichText(primary?.intro_text),
    textHTML: getRichText(primary?.text),
    listHeadingHTML: getRichText(primary?.list_heading),
    listItems: items?.map((item) => ({
      heading: notEmpty(item?.heading?.text),
      textHTML: getRichText(item?.text),
      buttonLink: item?.button_link?.url,
      buttonTarget: item?.button_link?.target,
      buttonText: notEmpty(item?.button_text?.text),
    })),
  }
}

PageBodyContentWithImage.mapDataToContext = () => ({
  bg: 'teal.90',
})

export const fragment = graphql`
  fragment PageBodyContentWithImage on Query {
    prismicPage(uid: { eq: $uid }) {
      data {
        body {
          ... on PrismicPageBodyContentWithImage {
            id
            primary {
              intro_text {
                text
                html
              }
              text {
                text
                html
              }
              image {
                alt
                fluid(
                  maxWidth: 600
                  imgixParams: { duotone: "515959,d4eaea", duotoneAlpha: 100 }
                ) {
                  ...GatsbyPrismicImageFluid
                }
              }
              button_link {
                url
                target
              }
              button_text {
                text
              }
              list_heading {
                text
                html
              }
            }
            items {
              heading {
                text
              }
              text {
                text
                html
              }
              button_link {
                url
                target
              }
              button_text {
                text
              }
            }
          }
        }
      }
    }
  }
`
