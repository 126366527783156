import React, { useEffect } from 'react'
import { a, useSpring, config } from 'react-spring'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

import { useMapReducer } from '../mapReducer'

import { Box } from 'system'
import {
  MapControlsBoundedBox,
  MapControlsExpand,
  AirportControls,
  FilterControls,
  MapDrawerButton,
  ShowControls,
} from 'src/components'

export const MapControls = ({ airportsOrder }) => {
  const [state, dispatch] = useMapReducer()

  const openDrawer = () => dispatch({ type: 'OPEN_FILTER_DRAWER' })
  const closeDrawer = () => dispatch({ type: 'CLOSE_FILTER_DRAWER' })
  const toggleDrawer = () =>
    state.state === 'FILTER_DRAWER' ? closeDrawer() : openDrawer()

  const isDrawerOpen = state.state === 'FILTER_DRAWER'

  const [drawerRef, { height }] = useMeasure({ polyfill: ResizeObserver })
  const closedY = -1 * height

  const [{ y }, set] = useSpring(() => ({
    y: closedY === 0 ? -300 : closedY,
  }))

  useEffect(() => {
    if (isDrawerOpen) set({ y: 0, config: config.default })
    else set({ y: closedY, config: config.default })
  }, [isDrawerOpen, closedY, set])

  return (
    <Box position="relative">
      <MapDrawerButton
        onClick={toggleDrawer}
        arrowRotation={isDrawerOpen ? 180 : 0}
        zIndex="showControlsDrawer"
        text={isDrawerOpen ? 'Hide Controls' : 'Show Controls'}
      />

      <Box
        as={a.div}
        ref={drawerRef}
        style={{ transform: y.to((y) => `translateY(${y}px)`) }}
        position={['absolute', null, 'static']}
        left={0}
        right={0}
        zIndex="controlsDrawer"
        bg="gray.10"
        transform={[null, null, 'translateY(0%) !important']}
        minHeight={[null, null, '40rem', '48rem']}
      >
        <MapControlsExpand title="Airport">
          <AirportControls airportsOrder={airportsOrder} />
        </MapControlsExpand>

        {/* <MapControlsBoundedBox
          ptScale={['m', null, 's']}
          pbScale={['m', null, '0']}
          withBottomBorder={[true, true, false]}
          log={true}
        >
          <ShowControls />
        </MapControlsBoundedBox> */}

        <MapControlsExpand
          title="Filter"
          withBottomExpandBorder={false}
          ptScale="m"
        >
          <FilterControls airportsOrder={airportsOrder} />
        </MapControlsExpand>
      </Box>

      {/* Background for mobile gestures */}
      <Box
        as={a.div}
        style={{ transform: y.to((y) => `translateY(${y - 20}%)`) }}
        display={['block', null, 'none']}
        position="absolute"
        bg="gray.10"
        left={0}
        right={0}
        top={0}
        height="12rem"
        zIndex="controlsDrawerBG"
        css={`
          pointer-events: none;
        `}
      />
    </Box>
  )
}
