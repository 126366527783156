import React from 'react'
import { notEmpty } from 'helpers'

import { Box, Grid, Flex } from 'system'

import {
  AirportCircle,
  Heading,
  Subheading,
  IslandSVG,
  ProgressBar,
  NotAvailableLabel,
} from 'src/components'

export const ProjectIntro = ({ projectData, airportData }) => {
  const statusDoc = projectData?.status?.document

  const statusItems = projectData?.status_labels?.filter(
    (item) => item?.status_document?.document,
  )
  const statusNameOverrides = statusItems?.map?.(
    (item) =>
      item?.status_document?.document?.data?.progress_bar_title_override?.text,
  )
  const statusNames = statusItems?.map?.(
    (item, i) =>
      statusNameOverrides[i] ||
      item?.status_document?.document?.data?.title?.text,
  )
  const statusLabels = statusItems?.map?.((item) => item?.status_label?.text)
  const stage =
    statusDoc?.data?.progress_bar_title_override?.text ||
    statusDoc?.data?.title?.text

  return (
    <Grid
      gridTemplateColumns={['1fr', null, '2fr 1fr']}
      gridColumnGapScale="l"
      pyScale="l"
      alignItems="center"
    >
      <Grid gridGapScale={['m', 's']}>
        <Subheading color="black" lineHeight="solid">
          Progress
        </Subheading>
        {notEmpty(statusNames) ? (
          <Box mlScale={['m', 0]}>
            <ProgressBar
              stageNames={statusNames}
              stageDescriptions={statusLabels}
              stage={stage}
              stagePercentCompleted={Number.parseInt(
                projectData?.status_percent_completed,
              )}
            />
          </Box>
        ) : (
          <NotAvailableLabel />
        )}
      </Grid>

      <Grid
        display={['none', null, 'grid']}
        gridAutoFlow="column"
        alignItems="end"
      >
        <Flex alignItems="center">
          <AirportCircle
            mrScale="s"
            width="4rem"
            height="4rem"
            fontSizeScale="s"
          >
            {airportData?.code?.text}
          </AirportCircle>
          <Heading fontSizeScale="b-" color="teal.40" maxWidth="20ch">
            {airportData?.full_name?.text}
          </Heading>
        </Flex>
        <IslandSVG
          width="7.75rem"
          display={['none', null, null, 'block']}
          airportCode={airportData?.code?.text}
        />
      </Grid>
    </Grid>
  )
}
