import React from 'react'

import { Input, Flex, Text, SVG } from 'system'
import { theme } from 'src/theme'
import { ReactComponent as AssetIconCheckSVG } from 'src/assets/icon-check.svg'

export const Checkbox = ({
  label,
  name,
  as = 'input',
  checked,
  defaultChecked,
  ...props
}) => {
  return (
    <Flex as="label" cursor="pointer" css={{ userSelect: 'none' }} {...props}>
      <Input
        as={as}
        display="none"
        checked={checked}
        defaultChecked={defaultChecked}
        type="checkbox"
        name={name}
      />
      <Flex
        alignItems="start"
        bg="gray.30"
        mrScale="t"
        fontSize="1rem"
        justifyContent="center"
        lineHeight="1"
        fontWeight="bold"
        width="18px"
        height="18px"
        css={`
          input:checked + & > div {
            opacity: 1 !important;
          }

          font-family: ${theme.fonts.sans};
        `}
      >
        <SVG
          x={1}
          y={1}
          width="18px"
          svg={AssetIconCheckSVG}
          position="relative"
          top="-3px"
          right="-3px"
          opacity={0}
          transitionProperty="opacity"
          transitionDuration="fast"
        />
      </Flex>
      <Text
        color="white"
        fontWeight="semibold"
        fontSizeScale={['b', null, 's']}
      >
        {label}
      </Text>
    </Flex>
  )
}
