import React from 'react'

import { Flex, Text, Button as ButtonBase, SVG } from 'system'
import { ReactComponent as AssetIconExternalSVG } from 'src/assets/icon-external.svg'

import { theme } from 'src/theme'

const variantMap = {
  teal: {
    bg: 'teal.60',
    color: 'white',
    borderColor: 'teal.60',
    hoverBg: theme.colors.white,
    hoverColor: theme.colors.teal[60],
  },
  transparent: {
    bg: 'transparent',
    color: 'white',
    borderColor: 'white',
    hoverBg: theme.colors.white,
    hoverColor: theme.colors.teal[60],
  },
}

export const PillButton = ({
  children,
  variant = 'teal',
  withIcon = true,
  ...props
}) => {
  const { bg, color, hoverBg, hoverColor, borderColor } =
    variantMap[variant] || variantMap.teal

  return (
    <ButtonBase
      borderRadius="10em"
      bg={bg}
      color={color}
      border="2px solid"
      borderColor={borderColor}
      fontSizeScale="s"
      fontWeight="bold"
      lineHeight="solid"
      transitionProperty="background-color, color"
      outline="none"
      {...props}
      css={{
        '&:hover, &:focus': {
          backgroundColor: hoverBg,
          color: hoverColor,
        },
      }}
    >
      <Flex alignItems="center" pyScale="t" pxScale="s" height="100%">
        <Text flexGrow="1" textStyle="trackedCaps">
          {children}
        </Text>
        {withIcon && (
          <SVG
            x={1}
            y={1}
            svg={AssetIconExternalSVG}
            width="1rem"
            mlScale="xl"
            flexShrink="0"
          />
        )}
      </Flex>
    </ButtonBase>
  )
}
