import React, { useState } from 'react'
import { Location } from '@reach/router'
import { linearScale } from 'styled-system-scale'
import { useKeyPress } from 'hooks'
import { isPathActive } from 'helpers'

import { Box, Text, Flex, Button } from 'system'

import {
  NavLink,
  Logo,
  BoundedBox,
  SearchInput,
  HamburgerButton,
  MobileNav,
  SearchIcon,
} from 'src/components'
import { usePrimaryNavigationData } from 'src/hooks'

export const PageBodyHeader = ({
  isSearchInitiallyOpen,
  initialQuery,
  ...props
}) => {
  const [isSearchOpen, setIsSearchOpen] = useState(isSearchInitiallyOpen)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const navData = usePrimaryNavigationData()

  const toggleMobileMenu = () =>
    setIsMobileMenuOpen((isMobileMenuOpen) => !isMobileMenuOpen)
  const closeMobileMenu = () => setIsMobileMenuOpen(false)

  useKeyPress('Escape', () => setIsSearchOpen(false))

  return (
    <Box as="header" position="relative" height={['70px', '96px', 'auto']}>
      <BoundedBox
        position="relative"
        bg="white"
        zIndex="header"
        pyScale={['s', 'm']}
        borderBottom="4px solid"
        borderColor="green.50"
        maxWidth="none"
        {...props}
      >
        <Flex
          alignItems={['center', 'baseline']}
          justifyContent="space-between"
        >
          <Logo width={linearScale('12.5rem', '20rem', { count: 5 })} />

          <HamburgerButton
            isOpen={isMobileMenuOpen}
            onClick={toggleMobileMenu}
            display={['block', 'none']}
            zIndex={8}
          />

          <Text
            as="nav"
            lineHeight="solid"
            fontSizeScale="s"
            fontWeight="bold"
            color="gray.50"
            display={['none', 'block']}
          >
            <Flex as="ul" alignItems="center">
              <Location>
                {({ location }) =>
                  navData.map((item) => {
                    const href = item?.primary?.link?.url
                    const target = item?.primary?.link?.target
                    const name = item?.primary?.name
                    const isActive = isPathActive(href, location)

                    return (
                      <NavLink
                        href={href}
                        key={name}
                        target={target}
                        isActive={isActive && !isSearchOpen}
                        mrScale="l"
                      >
                        {name}
                      </NavLink>
                    )
                  })
                }
              </Location>
              <NavLink
                isActive={isSearchOpen}
                innerAs={Button}
                onClick={() => setIsSearchOpen((isSearchOpen) => !isSearchOpen)}
                withWideLineHover={true}
              >
                <SearchIcon as={Button} width="1.25rem" color="inherit" />
              </NavLink>
            </Flex>
          </Text>
        </Flex>
      </BoundedBox>
      <SearchInput
        isOpen={isSearchOpen}
        zIndex="searchBar"
        initialQuery={initialQuery}
      />
      <MobileNav
        isOpen={isMobileMenuOpen}
        zIndex="searchBar"
        navData={navData}
        closeMobileMenu={closeMobileMenu}
        initialQuery={initialQuery}
      />
      <Box
        display={[null, 'none']}
        position="fixed"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex="mobileNavigationOverlay"
        opacity={isMobileMenuOpen ? 0.5 : 0}
        bg="black"
        transitionProperty="opacity"
        onClick={closeMobileMenu}
        css={{ pointerEvents: isMobileMenuOpen ? 'auto' : 'none' }}
      />
    </Box>
  )
}

PageBodyHeader.mapDataToProps = ({ data }) => ({
  initialQuery: data.initialQuery,
  isSearchInitiallyOpen: data.isSearchInitiallyOpen,
})
