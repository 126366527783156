import React, { useState, useRef } from 'react'
import { useSpring, a } from 'react-spring'
import { navigate } from 'gatsby'
import queryString from 'query-string'

import { Text, Flex, Input, Button, Box } from 'system'
import { BoundedBox, SearchIcon, InputClearIcon } from 'src/components'

export const SearchInput = ({ isOpen, initialQuery = '', ...props }) => {
  const inputRef = useRef(null)
  const [query, setQuery] = useState(initialQuery)

  const handleSubmit = (e) => {
    e.preventDefault()
    navigate(`/search/?${queryString.stringify({ query })}`)
  }

  const handleChange = (e) => setQuery(e.target.value)

  const onStart = () => {
    if (!inputRef.current) return
    if (isOpen) inputRef.current.focus()
  }

  const onRest = () => {
    if (!inputRef.current) return
    if (!isOpen) inputRef.current.blur()
  }

  const { transform, innerTransform } = useSpring({
    transform: isOpen ? 'translateY(0%)' : 'translateY(-101%)',
    innerTransform: isOpen ? 'translateY(0%)' : 'translateY(101%)',
    onStart,
    onRest,
  })

  return (
    <BoundedBox
      as={a(BoundedBox)}
      display={['none', 'block']}
      position="absolute"
      left={0}
      right={0}
      style={{ transform }}
      bg="green.50"
      maxWidth="medium"
      pyScale="t"
      overflowY="hidden"
      aria-hidden={!isOpen}
      {...props}
    >
      <form onSubmit={handleSubmit}>
        <Flex
          as={a(Flex)}
          style={{ transform: innerTransform }}
          bg="white"
          borderRadius="28px"
          position="relative"
          plScale="m"
        >
          <Box position="relative" flexGrow="1">
            <Input
              ref={inputRef}
              value={query}
              onChange={handleChange}
              pyScale="t-"
              plScale="s"
              prScale="l"
              outline="none"
              width="100%"
              tabIndex={isOpen ? 0 : -1}
            />
            {query !== '' && (
              <Button
                type="button"
                position="absolute"
                rightScale="s"
                top="50%"
                transform="translateY(-50%)"
                color="green.50"
                onClick={() => setQuery('')}
                css={(p) => ({
                  '&:hover, &:focus': {
                    color: p.theme.colors.gray[10],
                  },
                })}
              >
                <InputClearIcon color="inherit" />
              </Button>
            )}
          </Box>

          <Text
            fontSizeScale="xt"
            textAlign="center"
            fontWeight="medium"
            fontFamily="sansExt"
            borderLeft="2px solid"
            borderColor="green.50"
            bg="white"
            borderRadius="0 28px 28px 0"
          >
            <Button
              display="block"
              borderRadius="0 28px 28px 0"
              transitionProperty="background"
              height="100%"
              width="100%"
              pxScale="m"
              textAlign="center"
              tabIndex={isOpen ? 0 : -1}
              textStyle="trackedCapsWide"
              css={(p) => ({
                '&:hover, &:focus': {
                  backgroundColor: p.theme.colors.teal[90],
                },
              })}
            >
              Search
            </Button>
          </Text>
          <SearchIcon
            position="absolute"
            leftScale="t"
            top="50%"
            transform="translateY(-50%)"
          />
        </Flex>
      </form>
    </BoundedBox>
  )
}
