import React, { useMemo, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useFlexSearch } from 'react-use-flexsearch'
import { notEmpty } from 'helpers'

import { Box, Text, Grid, Flex, Link } from 'system'

import { BoundedBox, ListTable, Rule, Heading, Button } from 'src/components'
import { Highlighter } from 'src/components/Highlighter'

const HighlightedWord = ({ children }) => {
  return (
    <Box display="inline" as="em" bg="teal.70" style={{ fontStyle: 'normal' }}>
      {children}
    </Box>
  )
}

const ListSearchResults = ({
  heading,
  results,
  emptyMessage,
  query,
  withProjectNumbers = true,
  ...props
}) => {
  const containerRef = useRef()

  return (
    <Box ref={containerRef} {...props}>
      <Heading mbScale="m">{heading}</Heading>

      <Rule color="teal.40" mbScale="t" />
      <ListTable
        containerRef={containerRef}
        emptyMessage={emptyMessage}
        list={results}
        withFirstRuleOnMobile={false}
        withReducedHeadingRightPadding={true}
        renderHeadings={() => (
          <>
            <ListTable.Heading>
              {withProjectNumbers && 'Project No'}
            </ListTable.Heading>
            <ListTable.Heading>Begins</ListTable.Heading>
            <ListTable.Heading>Ends</ListTable.Heading>
            <ListTable.Heading>Zone</ListTable.Heading>
          </>
        )}
        renderItem={(result) => (
          <ListTable.Item
            key={result.title}
            name={
              <Highlighter
                searchTerm={query}
                renderHighlight={(word) => (
                  <HighlightedWord>{word}</HighlightedWord>
                )}
              >
                {result.title}
              </Highlighter>
            }
            href={result.url}
            bg="teal.90"
            prScale={['m', 0]}
          >
            <ListTable.ItemData label={withProjectNumbers && 'Project No'}>
              {withProjectNumbers && result?.projectNumber}
            </ListTable.ItemData>
            <ListTable.ItemData label="Begins">
              {notEmpty(result?.startDate)}
            </ListTable.ItemData>
            <ListTable.ItemData label="Ends">
              {notEmpty(result?.endDate)}
            </ListTable.ItemData>
            <ListTable.ItemData label="Zone">{result?.zone}</ListTable.ItemData>
          </ListTable.Item>
        )}
      />
    </Box>
  )
}

export const PageBodySearchResults = ({ query, ...props }) => {
  const queryData = useStaticQuery(graphql`
    {
      localSearchProjects {
        index
        store
      }
      localSearchClosures {
        index
        store
      }
      localSearchPages {
        index
        store
      }
    }
  `)

  const memoizedProjectStore = useMemo(
    () => JSON.parse(queryData.localSearchProjects.store),
    [queryData.localSearchProjects.store],
  )

  const memoizedClosureStore = useMemo(
    () => JSON.parse(queryData.localSearchClosures.store),
    [queryData.localSearchClosures.store],
  )

  const memoizedPageStore = useMemo(
    () => JSON.parse(queryData.localSearchPages.store),
    [queryData.localSearchPages.store],
  )

  const projectResults = useFlexSearch(
    query,
    queryData.localSearchProjects.index,
    memoizedProjectStore,
  )
  const closureResults = useFlexSearch(
    query,
    queryData.localSearchClosures.index,
    memoizedClosureStore,
  )
  const pageResults = useFlexSearch(
    query,
    queryData.localSearchPages.index,
    memoizedPageStore,
  )

  const hasPageResults = pageResults.length > 0

  return (
    <BoundedBox as="section" bg="teal.90" ptScale={['l', '3xl']} {...props}>
      {query === '' ? (
        <Box>
          <Heading as="h2" mbScale="t" display={['none', 'block']}>
            Enter a search term above.
          </Heading>
          <Heading as="h2" mbScale="t" display={['block', 'none']}>
            Enter a search term from the mobile menu.
          </Heading>
          <Text as="p">Results will appear here.</Text>
        </Box>
      ) : (
        <>
          <ListSearchResults
            heading="Projects"
            emptyMessage={`No projects found for: "${query}"`}
            query={query}
            results={projectResults}
          />

          <ListSearchResults
            heading="Closures"
            emptyMessage={`No closures found for: "${query}"`}
            query={query}
            results={closureResults}
            mtScale="3xl"
            withProjectNumbers={false}
          />

          <Box mtScale="3xl">
            <Heading mbScale="m">Pages</Heading>
            <Rule color="teal.40" mbScale="t" />

            {!hasPageResults && (
              <Text color="teal.50" myScale="l" textAlign={[null, 'center']}>
                No pages found for: "{query}"
              </Text>
            )}

            {hasPageResults && (
              <Grid gridRowGap="1px" bg="teal.80">
                {pageResults.map((result) => (
                  <Box key={result.title} pyScale="m" bg="teal.90">
                    <Flex
                      justifyContent="space-between"
                      mbScale="s"
                      alignItems="center"
                    >
                      <Heading
                        fontSizeScale={['l', 'm']}
                        fontWeight="semibold"
                        mrScale="t"
                      >
                        <Link
                          href={result.url}
                          outline="none"
                          color="gray.10"
                          transitionProperty="color"
                          css={(p) => ({
                            '&:hover, &:focus': {
                              color: p.theme.colors.teal[40],
                            },
                          })}
                        >
                          {result.title}
                        </Link>
                      </Heading>
                      <Button as={Link} withArrowIcon={true} href={result.url}>
                        More Info
                      </Button>
                    </Flex>

                    <Text as="p" maxWidth="65ch">
                      <Highlighter
                        searchTerm={query}
                        renderHighlight={(word) => (
                          <HighlightedWord>{word}</HighlightedWord>
                        )}
                      >
                        {result.excerpt}
                      </Highlighter>
                    </Text>
                  </Box>
                ))}
              </Grid>
            )}

            <Rule color="teal.40" mtScale="t" />
          </Box>
        </>
      )}
    </BoundedBox>
  )
}

PageBodySearchResults.mapDataToProps = ({ data }) => ({
  query: data.query,
})
