import React, { useState } from 'react'
import { Marker, Source, Layer, Popup } from 'react-map-gl'
import ConditionalWrap from 'conditional-wrap'

import { useIsMobile } from '../hooks/useIsMobile'
import { useStatuses } from '../hooks/useStatuses'
import { useMapReducer } from '../mapReducer'
import {
  DEFAULT_LAYER_FILL_COLOR,
  MAP_OVERLAY_FILL_DEFAULTS,
  MAP_OVERLAY_LINE_DEFAULTS,
} from '../constants'

import { Button } from 'system'
import { MapIcon } from 'src/components'
import { FeaturePopup, FeatureListPopup } from './MapPopup'
import { MapHoverPopup } from './MapHoverPopup'

export const MapMarker = ({
  id,
  latitude,
  longitude,
  type,
  polygon,
  title,
  feature,
  features,
  ...props
}) => {
  const [state, dispatch] = useMapReducer()
  const [isFocused, setIsFocused] = useState(false)
  const isMobile = useIsMobile()
  const statuses = useStatuses()

  const openPopup = () => dispatch({ type: 'OPEN_POPUP', payload: id })
  const closePopup = () => dispatch({ type: 'CLOSE_POPUP' })

  const isPopupOpen = state.state === 'POPUP' && state.activeMarker === id
  const status = statuses.find(
    (status) => status.uid === feature?.properties?.marker?.status,
  )
  const statusColor = status?.data?.color ?? DEFAULT_LAYER_FILL_COLOR

  const anchorPoint = isMobile ? 'top' : 'top-left'
  const offsetTop = isMobile ? 16 : -36
  const offsetLeft = isMobile ? 0 : -36

  return (
    <ConditionalWrap
      condition={polygon}
      wrap={(children) => (
        <Source type="geojson" data={polygon}>
          {children}
          {isFocused || isPopupOpen ? (
            <Layer
              type="fill"
              paint={{
                ...MAP_OVERLAY_FILL_DEFAULTS,
                'fill-color': statusColor,
              }}
            />
          ) : (
            <></>
          )}
          {isFocused || isPopupOpen ? (
            <Layer
              type="line"
              paint={{
                ...MAP_OVERLAY_LINE_DEFAULTS,
                'line-color': statusColor,
              }}
            />
          ) : (
            <></>
          )}
        </Source>
      )}
    >
      <Marker latitude={latitude} longitude={longitude}>
        <MapIcon
          as={Button}
          transform="translate(-50%, -50%)"
          type={type}
          onClick={openPopup}
          onMouseEnter={() => setIsFocused(true)}
          onMouseLeave={() => setIsFocused(false)}
          {...props}
        />
      </Marker>
      {isFocused && !isPopupOpen && title ? (
        <MapHoverPopup
          longitude={longitude}
          latitude={latitude}
          css={{ zIndex: 1 }}
        >
          {title}
        </MapHoverPopup>
      ) : (
        <></>
      )}
      {isPopupOpen ? (
        <Popup
          longitude={longitude}
          latitude={latitude}
          closeButton={false}
          closeOnClick={false}
          anchor={anchorPoint}
          offsetTop={offsetTop}
          offsetLeft={offsetLeft}
          tipSize={0}
          css={{
            zIndex: 1,
            '.mapboxgl-popup-content': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              padding: 0,
            },
          }}
        >
          {features ? (
            <FeatureListPopup
              features={features}
              type={state.activeType}
              close={closePopup}
            />
          ) : (
            <FeaturePopup feature={feature} type={type} close={closePopup} />
          )}
        </Popup>
      ) : (
        <></>
      )}
    </ConditionalWrap>
  )
}
