import React from 'react'
import { Box } from 'system'

export const ContentBox = ({ children }) => (
  <Box
    as="section"
    borderBottom="1px solid"
    borderColor="gray.80"
    boxStyle="lastNoBorder"
    pyScale="l"
    pxScale={['m', 0]}
    mxScale={['-m', 0]}
  >
    {children}
  </Box>
)
