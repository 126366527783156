import React from 'react'
import { Popup } from 'react-map-gl'

import { Text } from 'system'

export const MapHoverPopup = ({ longitude, latitude, children, ...props }) => {
  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      closeButton={false}
      closeOnClick={false}
      anchor="bottom"
      offsetTop={-24}
      tipSize={0}
      {...props}
      css={{
        '.mapboxgl-popup-content': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          padding: 0,
        },
      }}
    >
      <Text
        bg="white"
        borderRadius="8px"
        boxShadow="0 1px 2px rgba(0, 0, 0, 0.3), 0 4px 6px rgba(0, 0, 0, 0.2)"
        cursor="default"
        position="relative"
        overflow="hidden"
        pyScale="t-"
        pxScale="t"
        fontSizeScale="t"
        fontWeight="semibold"
        textAlign="center"
        maxWidth="24ch"
      >
        {children}
      </Text>
    </Popup>
  )
}
