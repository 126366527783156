import React from 'react'

import { BoundedBox } from 'system'

export const MapControlsBoundedBox = ({
  children,
  withBottomBorder = [true],
  ...props
}) => {
  return (
    <BoundedBox
      width="100%"
      ptScale="s"
      pbScale="m"
      pxScale="s+"
      borderBottom={withBottomBorder.map((bool) =>
        bool ? '1px solid' : 'none',
      )}
      borderColor={['black', 'black', 'green.50']}
      {...props}
    >
      {children}
    </BoundedBox>
  )
}
