import React, { useMemo } from 'react'
import shallow from 'zustand/shallow'
import { useSpring, a } from 'react-spring'

import { useSpeedBumpStore } from 'src/hooks'

import { Box, Text, Grid, Button, Link } from 'system'
import { Heading, PillButton, BoundedBox, Anchor } from 'src/components'

const CloseButton = (props) => {
  return (
    <Button
      position="absolute"
      width={['1rem', '1.25rem']}
      css={{
        '&:hover, &:focus': {
          '*': {
            background: 'white !important',
          },
        },
      }}
      {...props}
    >
      <Box
        height="2px"
        bg="gray.80"
        mb="4px"
        transform="rotate(45deg) translateX(4px)"
        transitionProperty="background"
      />
      <Box
        height="2px"
        bg="gray.80"
        transform="rotate(-45deg) translateX(4px)"
        transitionProperty="background"
      />
    </Button>
  )
}

export const SpeedBump = (props) => {
  const { isSpeedBumpVisible, hideSpeedBump, href } = useSpeedBumpStore(
    (store) => ({
      isSpeedBumpVisible: store.isSpeedBumpVisible,
      hideSpeedBump: store.hideSpeedBump,
      href: store.href,
    }),
    shallow,
  )

  const { opacity: overlayOpacity } = useSpring({
    opacity: isSpeedBumpVisible ? 0.8 : 0,
  })
  const { opacity: modalOpacity, transform: modalTransform } = useSpring({
    opacity: isSpeedBumpVisible ? 1 : 0,
    transform: isSpeedBumpVisible ? 'translateY(0%)' : 'translateY(100%)',
  })

  const host = useMemo(() => {
    if (!href) return

    const url = new URL(href)

    return url.host
  }, [href])

  return (
    <>
      <Box
        as={a(Box)}
        style={{
          opacity: overlayOpacity,
          pointerEvents: isSpeedBumpVisible ? 'auto' : 'none',
        }}
        position="fixed"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex="speedBumpOverlay"
        bg="white"
        onClick={hideSpeedBump}
      />

      <BoundedBox
        as={a(BoundedBox)}
        style={{
          opacity: modalOpacity,
          pointerEvents: isSpeedBumpVisible ? 'auto' : 'none',
        }}
        position="fixed"
        width="100%"
        bottom={[0, null, 'unset']}
        top={[null, null, '50%']}
        zIndex="speedBumpModal"
        pyScale={0}
        pxScale={['t-', null, 'm']}
        transform={[null, null, 'translateY(-50%)']}
        {...props}
      >
        <Box
          as={a(Box)}
          style={{ transform: modalTransform }}
          position="relative"
          bg="teal.40"
          pyScale={['l', 'xl', '3xl']}
          pxScale={['m', 'xl']}
          borderRadius={['20px 20px 0 0', null, '20px']}
        >
          <CloseButton
            topScale={['s', 's+']}
            right={['1rem', 'unset']}
            left={[null, '1rem']}
            onClick={hideSpeedBump}
          />

          <Box mbScale="l">
            <Heading color="white" mbScale="t-">
              You are now departing.
            </Heading>
            {host && (
              <Text>
                You will be redirected to{' '}
                <Anchor
                  href={href}
                  target="_blank"
                  variant="underlinedWhiteHover"
                  withExternalCheck={false}
                >
                  {host}
                </Anchor>
                .
              </Text>
            )}
          </Box>

          <Grid
            gridGapScale="s"
            gridTemplateColumns={['1fr', 'auto auto']}
            justifyContent="start"
          >
            <PillButton
              as={Link}
              href={href}
              target="_blank"
              variant="transparent"
              withIcon={false}
            >
              Continue
            </PillButton>
            <PillButton
              variant="transparent"
              withIcon={false}
              onClick={hideSpeedBump}
            >
              Stay Right Here
            </PillButton>
          </Grid>
        </Box>
      </BoundedBox>
    </>
  )
}
