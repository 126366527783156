import { graphql } from 'gatsby'

// 1. Import your slice
import { PageBodyAirportClosuresList } from './PageBodyAirportClosuresList'
import { PageBodyAirportProjectsList } from './PageBodyAirportProjectsList'
import { PageBodyContentWithImage } from './PageBodyContentWithImage'
import { PageBodyFooter } from './PageBodyFooter'
import { PageBodyHeader } from './PageBodyHeader'
import { PageBodyLastUpdated } from './PageBodyLastUpdated'
import { PageBodyMap } from './PageBodyMap'
import { PageBodyNotFound } from './PageBodyNotFound'
import { PageBodySearchBar } from './PageBodySearchBar'
import { PageBodySearchResults } from './PageBodySearchResults'
import { PageBodySectionHeader } from './PageBodySectionHeader'

// 2. Add your slice
export const slicesMap = {
  PageBodyAirportClosuresList,
  PageBodyAirportProjectsList,
  PageBodyContentWithImage,
  PageBodyFooter,
  PageBodyHeader,
  PageBodyLastUpdated,
  PageBodyMap,
  PageBodyNotFound,
  PageBodySearchBar,
  PageBodySearchResults,
  PageBodySectionHeader,
}

// 3. Add your slice fragment
export const fragment = graphql`
  fragment SlicesPageBody on Query {
    ...PageBodyAirportClosuresList
    ...PageBodyAirportProjectsList
    ...PageBodyContentWithImage
    ...PageBodyMap
    ...PageBodyNotFound
    ...PageBodySectionHeader

    # The following slices do not have fragments:
    # ...PageBodyFooter
    # ...PageBodyHeader
    # ...PageBodyLastUpdated
    # ...PageBodySearchBar
    # ...PageBodySearchResults
  }
`
