import React from 'react'
import css from '@styled-system/css'

import { SpeedBumpLink } from './SpeedBumpLink'

const variants = {
  default: {
    color: 'teal.40',
    colorHover: 'gray.30',
    textDecoration: 'none',
  },
  underlinedWhiteHover: {
    color: 'inherit',
    colorHover: 'white',
    textDecoration: 'underline',
  },
}

export const Anchor = ({
  variant: variantName = 'default',
  withExternalCheck = true,
  ...props
}) => {
  const variant = variants[variantName]

  return (
    <SpeedBumpLink
      color={variant.color}
      transitionProperty="color"
      withExternalCheck={withExternalCheck}
      {...props}
      css={css({
        textDecoration: variant.textDecoration,
        '&:hover, &:focus': { color: variant.colorHover },
      })}
    />
  )
}
