import React, { useReducer, useContext } from 'react'

import { arrsEqual } from './utils'

interface Marker {
  uid: string
  url: string
  type: 'project' | 'closure'
  title: string
  airport: string
  status: string
  statusLabels: Array<{ title: string; date: string }>
  zone: string
  startDate: string
  endDate: string
  arcgisId?: object
  polygon?: object
  point?: [number, number]
  externalUrl?: string
}

type Action =
  | { type: 'INITIALIZE'; payload: { map: any; markers: object[] } }
  | { type: 'OPEN_FILTER_DRAWER' }
  | { type: 'CLOSE_FILTER_DRAWER' }
  | { type: 'FILTER_AIRPORT'; payload: string }
  | { type: 'FILTER_TYPE'; payload: 'closure' | 'project' }
  | { type: 'FILTER_BY_STATUS'; payload: string }
  | { type: 'UNFILTER_BY_STATUS'; payload: string }
  | { type: 'FILTER_BY_ZONE'; payload: string }
  | { type: 'UNFILTER_BY_ZONE'; payload: string }
  | { type: 'FILTER_BY_PROJECT'; payload: string }
  | { type: 'UNFILTER_BY_PROJECT'; payload: string }
  | { type: 'FILTER_BY_CLOSURE'; payload: string }
  | { type: 'UNFILTER_BY_CLOSURE'; payload: string }
  | { type: 'OPEN_POPUP'; payload: number }
  | { type: 'CLOSE_POPUP' }
  | { type: '_IMPERATIVE_SET_REST_VIEW' }

interface State {
  state:
    | 'INITIALIZING'
    | 'AIRPORT_VIEW'
    | 'REST_VIEW'
    | 'FILTER_DRAWER'
    | 'POPUP'

  map: any

  activeAirport: string
  activeType: 'closure' | 'project'
  activeStatuses: string[]
  activeZones: string[]
  activeProjects: string[]
  activeClosures: string[]

  activeMarker?: number
  markers: Marker[]
  filteredMarkers: Marker[]
}

export const initialState: State = {
  state: 'INITIALIZING',

  map: undefined,

  activeAirport: 'hnl',
  activeType: 'project',
  activeStatuses: [],
  activeZones: [],
  activeProjects: [],
  activeClosures: [],

  activeMarker: undefined,
  markers: [],
  filteredMarkers: [],
}

const normalizeMarker = (rawMarker: any): Marker => ({
  uid: rawMarker?.uid,
  url: rawMarker?.url,
  type: rawMarker?.type,
  title: rawMarker?.data?.title?.text,
  airport: rawMarker?.data?.airport?.document?.uid,
  status: rawMarker?.data?.status?.document?.uid,
  statusLabels: rawMarker?.data?.status_labels?.map((l) => ({
    title: l?.status_document?.document?.data?.title?.text ?? '',
    date: l?.status_label?.text ?? '',
  })),
  zone: rawMarker?.data?.zone?.document?.uid,
  startDate: rawMarker?.fields?.start_date,
  endDate: rawMarker?.fields?.end_date,
  arcgisId: rawMarker?.data?.arcgis_id,
  polygon: rawMarker?.fields?.polygon,
  point: rawMarker?.fields?.point,
  externalUrl: rawMarker?.data?.external_url?.url,
})

const filterMarkers = (state: State): State => {
  const updatedFilteredMarkers = state.markers.filter((marker) => {
    let include =
      marker.airport === state.activeAirport && marker.type === state.activeType

    if (include && state.activeZones.length > 0)
      include = state.activeZones.includes(marker.zone)

    if (include && state.activeStatuses.length > 0)
      include = state.activeStatuses.includes(marker.status)

    if (include && state.activeProjects.length > 0)
      include = state.activeProjects.includes(marker.uid)

    if (include && state.activeClosures.length > 0)
      include = state.activeClosures.includes(marker.uid)

    return include
  })

  const didNotChange = arrsEqual(
    state.filteredMarkers.map((marker) => marker.uid).sort(),
    updatedFilteredMarkers.map((marker) => marker.uid).sort(),
  )

  if (didNotChange) return state

  return {
    ...state,
    filteredMarkers: updatedFilteredMarkers,
  }
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INITIALIZE': {
      const normalizedMarkers = action.payload.markers.map(normalizeMarker)

      return filterMarkers({
        ...initialState,
        state: 'REST_VIEW',
        map: action.payload.map,
        markers: normalizedMarkers,
        filteredMarkers: normalizedMarkers,
      })
    }

    case 'OPEN_FILTER_DRAWER': {
      return {
        ...state,
        state: 'FILTER_DRAWER',
      }
    }

    case 'CLOSE_FILTER_DRAWER': {
      return {
        ...state,
        state: 'REST_VIEW',
      }
    }

    case 'FILTER_AIRPORT': {
      return filterMarkers({
        ...state,
        state: 'AIRPORT_VIEW',
        activeAirport: action.payload,
        activeStatuses: [],
        activeZones: [],
        activeProjects: [],
        activeClosures: [],
      })
    }

    case 'FILTER_TYPE': {
      return filterMarkers({
        ...state,
        state: 'AIRPORT_VIEW',
        activeType: action.payload,
        activeStatuses: [],
        activeZones: [],
        activeProjects: [],
        activeClosures: [],
      })
    }

    case 'FILTER_BY_STATUS': {
      return filterMarkers({
        ...state,
        state: 'AIRPORT_VIEW',
        activeStatuses: [...state.activeStatuses, action.payload],
      })
    }

    case 'UNFILTER_BY_STATUS': {
      return filterMarkers({
        ...state,
        state: 'AIRPORT_VIEW',
        activeStatuses: state.activeStatuses.filter(
          (status) => status !== action.payload,
        ),
      })
    }

    case 'FILTER_BY_ZONE': {
      return filterMarkers({
        ...state,
        state: 'AIRPORT_VIEW',
        activeZones: [...state.activeZones, action.payload],
      })
    }

    case 'UNFILTER_BY_ZONE': {
      return filterMarkers({
        ...state,
        state: 'AIRPORT_VIEW',
        activeZones: state.activeZones.filter(
          (zone) => zone !== action.payload,
        ),
      })
    }

    case 'FILTER_BY_PROJECT': {
      return filterMarkers({
        ...state,
        state: 'AIRPORT_VIEW',
        activeProjects: [...state.activeProjects, action.payload],
      })
    }

    case 'UNFILTER_BY_PROJECT': {
      return filterMarkers({
        ...state,
        state: 'AIRPORT_VIEW',
        activeProjects: state.activeProjects.filter(
          (project) => project !== action.payload,
        ),
      })
    }

    case 'FILTER_BY_CLOSURE': {
      return filterMarkers({
        ...state,
        state: 'AIRPORT_VIEW',
        activeClosures: [...state.activeClosures, action.payload],
      })
    }

    case 'UNFILTER_BY_CLOSURE': {
      return filterMarkers({
        ...state,
        state: 'AIRPORT_VIEW',
        activeProjects: state.activeClosures.filter(
          (closure) => closure !== action.payload,
        ),
      })
    }

    case 'OPEN_POPUP': {
      return {
        ...state,
        state: 'POPUP',
        activeMarker: action.payload,
      }
    }

    case 'CLOSE_POPUP': {
      return {
        ...state,
        state: 'REST_VIEW',
        activeMarker: undefined,
      }
    }

    case '_IMPERATIVE_SET_REST_VIEW': {
      return {
        ...state,
        state: 'REST_VIEW',
      }
    }
  }
}

export const MapReducerContext = React.createContext([
  initialState,
  undefined as React.Dispatch<Action>,
] as const)

export const MapReducerProvider = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <MapReducerContext.Provider value={[state, dispatch]}>
      {children}
    </MapReducerContext.Provider>
  )
}

export const useMapReducer = () => useContext(MapReducerContext)
