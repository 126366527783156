import { graphql, useStaticQuery } from 'gatsby'

export const useAirports = ({ airportUID } = {}) => {
  const queryData = useStaticQuery(graphql`
    {
      allPrismicAirport {
        nodes {
          ...Airport
        }
      }
    }
  `)

  let airports = queryData.allPrismicAirport.nodes

  if (airportUID) airports = airports.find((a) => a?.uid === airportUID)

  return airports
}
