import React, { useState, useRef } from 'react'
import { graphql } from 'gatsby'
import { camelCase } from 'tiny-compose-fns'
import { propPairsEq, notEmpty } from 'helpers'

import { useProjects, useSettingsData } from 'src/hooks'

import { Box, Grid, Text, Flex } from 'system'
import {
  AirportCircle,
  Heading,
  IslandSVG,
  OffsetBoundedBox,
  Subheading,
  BackToTop,
  SortSelect,
  ListTable,
  Tabs,
  StatusList,
} from 'src/components'

const FilterInput = ({ value, onChange, ...props }) => (
  <Flex
    as="label"
    justifyContent={[null, 'flex-end']}
    alignItems="baseline"
    {...props}
  >
    <Text
      as="span"
      color="gray.50"
      textStyle="trackedCapsWide"
      fontWeight="bold"
      fontSizeScale="t"
      lineHeight="solid"
      mr="6px"
    >
      Sort by
    </Text>
    <SortSelect
      value={value}
      onChange={onChange}
      options={{
        'project-no': 'Project #',
        status: 'Status',
        zone: 'Zone',
      }}
    />
  </Flex>
)

export const PageBodyAirportProjectsList = ({
  displaySide = 'left',
  airportUID,
  airportCode,
  airportName,
  airportLocation,
  ...props
}) => {
  const ref = useRef()
  const [sortBy, setSortBy] = useState('project-no')
  const settings = useSettingsData()

  const currentProjects = useProjects({
    airportUID,
    negateStatusUID: settings.completed_status.uid,
    sortBy,
  })

  const handleFilterChange = (event) => setSortBy(event.target.value)

  const displaySideIsLeft = displaySide === 'left'
  const ruleVariant = displaySideIsLeft ? undefined : 'extendLeft'

  return (
    <OffsetBoundedBox
      ref={ref}
      id={airportUID}
      as="section"
      bg="teal.90"
      innerProps={{ pyScale: ['m', 'l'] }}
      offsetSide={displaySide}
      {...props}
    >
      <Grid
        gridRowGapScale="m"
        gridColumnGapScale="s"
        gridTemplateColumns={['repeat(4, 1fr)', 'repeat(8, 1fr)']}
        mbScale="l"
      >
        <Grid
          gridColumn="1 / -1"
          alignItems="center"
          gridGapScale="m"
          gridAutoFlow="column"
          gridTemplateColumns="auto 1fr"
        >
          <AirportCircle>{airportCode}</AirportCircle>
          <Box>
            <Heading
              as="h3"
              color="teal.60"
              fontSizeScale={['m+', 'l']}
              mbScale="t-"
              mrScale={[0, 'm']}
            >
              {airportName}
            </Heading>
            <Subheading color="gray.50" css={{ whiteSpace: 'nowrap' }}>
              {airportLocation}
            </Subheading>
          </Box>
          <IslandSVG
            display={['none', null, 'block']}
            airportCode={airportCode}
            width="8rem"
            justifySelf="end"
          />
        </Grid>
      </Grid>

      <Tabs
        renderAccessories={() => (
          <Box myScale={['t', 0]} prScale={[0, displaySideIsLeft ? 'm' : 0]}>
            <FilterInput value={sortBy} onChange={handleFilterChange} />
          </Box>
        )}
        ruleVariant={ruleVariant}
        mrScale={[0, displaySideIsLeft ? '-m' : 0]}
      >
        <Tabs.Tab key="current" name="Current Projects" shortName="Current">
          <ListTable
            containerRef={ref}
            emptyMessage="No current projects at this time"
            paramKey={`current-${airportUID}`}
            list={currentProjects}
            renderHeadings={() => (
              <>
                <ListTable.Heading>Project No.</ListTable.Heading>
                <ListTable.Heading>Status</ListTable.Heading>
                <ListTable.Heading>Zone</ListTable.Heading>
                <ListTable.Heading>Milestones</ListTable.Heading>
              </>
            )}
            renderItem={(project) => (
              <ListTable.Item
                name={project?.data?.title?.text}
                href={project?.data?.external_url?.url || project?.url}
              >
                <ListTable.ItemData label="Project No">
                  {notEmpty(project?.data?.project_number?.text)}
                </ListTable.ItemData>
                <ListTable.ItemData label="Status">
                  {project?.data?.status?.document?.data?.title?.text}
                </ListTable.ItemData>
                <ListTable.ItemData label="Zone">
                  {project?.data?.zone?.document?.data?.name?.text}
                </ListTable.ItemData>
                <ListTable.ItemData
                  label="Milestones"
                  gridColumn={['span 2', 'span 1']}
                >
                  <StatusList
                    statuses={project?.data?.status_labels?.map((l) => ({
                      title:
                        l?.status_document?.document?.data?.title?.text ?? '',
                      date: l?.status_label?.text ?? '',
                    }))}
                    withBorder={false}
                    pt={[2, 1, null, 0]}
                    maxWidth={['20rem', null, 'none']}
                  />
                </ListTable.ItemData>
              </ListTable.Item>
            )}
            renderFooterAccessories={() => (
              <Box
                myScale={['t', 0]}
                prScale={[0, displaySideIsLeft ? 'm' : 0]}
              >
                <BackToTop />
              </Box>
            )}
            ruleVariant={ruleVariant}
            mtScale={[0, null, null, 's+']}
          />
        </Tabs.Tab>
      </Tabs>
    </OffsetBoundedBox>
  )
}

PageBodyAirportProjectsList.mapDataToProps = ({
  data,
  context,
  nextContext,
  previousContext,
}) => {
  const airportDoc = data?.primary?.airport?.document

  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    previousSharesBg: propPairsEq('bg', context, previousContext),
    displaySide: notEmpty(camelCase(data?.primary?.display_side)),
    airportUID: airportDoc?.uid,
    airportCode: airportDoc?.data?.code?.text,
    airportName: airportDoc?.data?.full_name?.text,
    airportLocation: airportDoc?.data?.location?.text,
  }
}

PageBodyAirportProjectsList.mapDataToContext = () => ({
  bg: 'teal.90',
})

export const fragment = graphql`
  fragment PageBodyAirportProjectsList on Query {
    prismicPage(uid: { eq: $uid }) {
      data {
        body {
          ... on PrismicPageBodyAirportProjectsList {
            id
            primary {
              display_side
              airport {
                document {
                  ...Airport
                }
              }
            }
          }
        }
      }
    }
  }
`
