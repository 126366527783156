import React from 'react'

import { Flex, Button, Text, SVG } from 'system'
import { ReactComponent as AssetIconArrowDownSVG } from 'src/assets/icon-arrow-down.svg'

export const MapDrawerButton = React.memo(
  ({ text, onClick, arrowRotation = 0, ...props }) => {
    return (
      <Flex
        as={Button}
        position="relative"
        zIndex={2}
        bg="gray.10"
        color="green.50"
        pyScale="t"
        pxScale="s"
        alignItems="center"
        justifyContent="center"
        width="100%"
        borderBottom="1px solid"
        borderColor="black"
        display={['flex', null, 'none']}
        onClick={onClick}
        {...props}
      >
        <Text
          fontSizeScale="t"
          textStyle="trackedCapsWide"
          fontWeight="bold"
          fontFamily="sansExt"
          mrScale="t"
        >
          {text}
        </Text>
        <SVG
          x={1}
          y={1}
          width=".65rem"
          svg={AssetIconArrowDownSVG}
          color="green.50"
          transform={`rotate(${arrowRotation}deg)`}
        />
      </Flex>
    )
  },
)
