import React from 'react'

import { Box, Grid, Text, SVG, Flex } from 'system'
import { Subheading } from 'src/components'
import { ReactComponent as AssetIconTriangleSVG } from 'src/assets/triangle.svg'
import * as styles from './ProgressBar.module.css'

const StageLabel = ({ children, description, childrenProps, ...props }) => (
  <Grid as={Text} gridGap="0.25rem" {...props}>
    {children && (
      <Subheading
        as="span"
        variant="sansExt"
        fontSize={['10px', '8px', null, '9px']}
        color="gray.10"
        {...childrenProps}
      >
        {children}
      </Subheading>
    )}
    <Subheading
      as="span"
      variant="sansExt"
      fontSize={['10px', '8px']}
      color="gray.50"
      {...childrenProps}
    >
      {description}
    </Subheading>
  </Grid>
)

const stageVariants = {
  first: {
    textAlign: 'left',
    alignItems: 'flex-end',
    labelProps: {},
  },
  middle: {
    textAlign: 'center',
    alignItems: 'flex-end',
    labelProps: {
      mr: 'auto',
      transform: ['translateY(50%)', 'translateX(-50%)'],
    },
  },
}

const Stage = ({
  variant: variantName = 'middle',
  name,
  description,
  percentComplete,
  showArrow = false,
}) => {
  const variant = stageVariants[variantName]

  return (
    <Flex
      className={styles.stage}
      alignItems={[variant.alignItems, 'stretch']}
      flexDirection={[null, 'column']}
      flex={[null, '1 1 0%']}
      minWidth={[null, '15rem']}
    >
      <Grid
        bg="teal.90"
        alignItems="end"
        width={['2rem', '100%']}
        height={['4.75rem', '2rem']}
        flexShrink={0}
      >
        <Box
          bg="green.50"
          height={[`${percentComplete}%`, '100%']}
          width={['100%', `${percentComplete}%`]}
          position="relative"
        >
          {showArrow && (
            <SVG
              svg={AssetIconTriangleSVG}
              x={8}
              y={6}
              color="green.50"
              position="absolute"
              right={['auto', 0]}
              left={[0, 'auto']}
              top={0}
              transform={[
                'translate(calc(-100% - 2px), -55%) rotate(90deg)',
                'translate(55%, calc(-100% - 3px)) scaleY(-1)',
              ]}
              width={['0.7rem', '0.85rem']}
            />
          )}
        </Box>
      </Grid>

      <StageLabel
        description={description}
        textAlign={[null, variant.textAlign]}
        flexShrink={0}
        {...variant.labelProps}
      >
        {name}
      </StageLabel>
    </Flex>
  )
}

/**
 * @typedef {Object} Props
 * @property {string[]} [stageNames] - Array of stage names
 * @property {string[]} [stageDescriptions] - Array of stage descriptions
 * @property {string} stage - Current stage
 * @property {number} [stagePercentCompleted=0] - Percentage of current stage completed
 */

/**
 * Progress bar component that shows stages and completion
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const ProgressBar = React.forwardRef(
  (
    {
      stageNames = [],
      stageDescriptions = [],
      stage,
      stagePercentCompleted = 0,
      ...props
    },
    ref,
  ) => {
    const currentStageIndex = stageNames.findIndex((name) => stage === name)
    const lastStageIndex = stageNames.length - 1
    const showArrowStageIndex = Math.min(
      currentStageIndex,
      stageNames.length - 2,
    )

    return (
      <Flex
        flexDirection={['column-reverse', 'row']}
        gap="0.125rem"
        className={styles.progressBar}
        mxScale={['-t', '-m']}
        pxScale={['t', 'm']}
        ref={ref}
        {...props}
      >
        {stageNames.map((name, i) => {
          if (i === lastStageIndex) return null

          let percentComplete = 0
          if (i < currentStageIndex) percentComplete = 100
          if (i === currentStageIndex) percentComplete = stagePercentCompleted

          let variant = undefined
          if (i === 0) variant = 'first'

          return (
            <Stage
              key={name}
              variant={variant}
              name={name}
              description={stageDescriptions[i]}
              percentComplete={percentComplete}
              totalStagesCount={stageNames.length}
              index={i}
              showArrow={i === showArrowStageIndex}
            />
          )
        })}

        <StageLabel
          description={stageDescriptions[lastStageIndex]}
          ml={['3rem', 0]}
          mt={[null, '3rem']}
          height={[0, 'auto']}
          width={0}
          textAlign={[null, 'right']}
          childrenProps={{ transform: [null, 'translateX(-100%)'] }}
        >
          {stageNames[lastStageIndex]}
        </StageLabel>
      </Flex>
    )
  },
)
ProgressBar.displayName = 'ProgressBar'
