import React from 'react'
import css from '@styled-system/css'

import { Flex, Button as ButtonBase, SVG, AspectRatio } from 'system'
import { ReactComponent as AssetIconRightSVG } from 'src/assets/icon-arrow-right.svg'
import { ReactComponent as AssetIconLeftSVG } from 'src/assets/icon-arrow-left.svg'

const icons = {
  left: { svg: AssetIconLeftSVG, x: 19, y: 11 },
  right: { svg: AssetIconRightSVG, x: 19, y: 11 },
}

const variants = {
  base: {
    bg: 'transparent',
    bgDisabled: 'transparent',
    bgFocused: 'teal.40',
    borderColor: 'teal.40',
    borderColorDisabled: 'gray.70',
    borderColorFocused: 'teal.40',
    color: 'teal.40',
    colorDisabled: 'gray.70',
    colorFocused: 'white',
  },
}

export const ArrowButton = ({
  direction = 'right',
  disabled = false,
  variant: variantName = 'base',
  ...props
}) => {
  const icon = icons[direction]
  const variant = variants[variantName]

  return (
    <ButtonBase
      disabled={disabled}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      border="2px solid"
      borderRadius="50%"
      borderColor={disabled ? variant.borderColorDisabled : variant.borderColor}
      color={disabled ? variant.colorDisabled : variant.color}
      transitionProperty="background-color, color"
      width={['2.25rem', '2.75rem']}
      css={css({
        '&:hover, &:focus': {
          color: disabled ? variant.colorDisabled : variant.colorFocused,
          bg: disabled ? variant.bgDisabled : variant.bgFocused,
        },
      })}
      {...props}
    >
      <AspectRatio x={1} y={1}>
        <Flex alignItems="center" height="100%" justifyContent="center">
          <SVG svg={icon.svg} x={icon.x} y={icon.y} width="60%" />
        </Flex>
      </AspectRatio>
    </ButtonBase>
  )
}
