import React from 'react'
import { linearScale } from 'styled-system-scale'
import { a, config, useSpring } from 'react-spring'

import { delay } from 'src/utils'
import { Box, Button } from 'system'

export const HamburgerButton = ({ isOpen, ...props }) => {
  const topSpring = useSpring({
    from: {
      y: `translateY(${isOpen ? 45 : 0}%)`,
      rot: `rotate(${isOpen ? 45 : 0}deg)`,
    },
    to: async (next) => {
      if (isOpen) {
        next({ y: `translateY(${isOpen ? 45 : 0}%)` })
        await delay(200)
        next({ rot: `rotate(${isOpen ? 45 : 0}deg)` })
      } else {
        next({ rot: `rotate(${isOpen ? 45 : 0}deg)` })
        await delay(200)
        next({ y: `translateY(${isOpen ? 45 : 0}%)` })
      }
    },
    config: config.stiff,
  })

  const bottomSpring = useSpring({
    from: {
      y: `translateY(${isOpen ? -45 : 0}%)`,
      rot: `rotate(${isOpen ? -45 : 0}deg)`,
    },
    to: async (next) => {
      if (isOpen) {
        next({ y: `translateY(${isOpen ? -45 : 0}%)` })
        await delay(200)
        next({ rot: `rotate(${isOpen ? -45 : 0}deg)` })
      } else {
        next({ rot: `rotate(${isOpen ? -45 : 0}deg)` })
        await delay(200)
        next({ y: `translateY(${isOpen ? -45 : 0}%)` })
      }
    },
    config: config.stiff,
  })

  return (
    <Button
      width={linearScale('20px', '30px', { count: 5 })}
      height={linearScale('13px', '19px', { count: 5 })}
      position="relative"
      outline="none"
      {...props}
    >
      <Box
        as={a(Box)}
        position="absolute"
        top={0}
        left={0}
        height="100%"
        width="100%"
        style={{ transform: topSpring.y }}
      >
        <Box
          as={a(Box)}
          bg="currentColor"
          height="2px"
          left={0}
          position="absolute"
          top={0}
          width="100%"
          style={{ transform: topSpring.rot }}
        />
      </Box>
      <Box
        as={a(Box)}
        position="absolute"
        top={0}
        left={0}
        height="100%"
        width="100%"
        style={{ transform: bottomSpring.y }}
      >
        <Box
          as={a(Box)}
          bg="currentColor"
          bottom={0}
          height="2px"
          left={0}
          position="absolute"
          width="100%"
          style={{ transform: bottomSpring.rot }}
        />
      </Box>
    </Button>
  )
}
