import React from 'react'

export const Highlighter = ({
  children = '',
  searchTerm,
  renderHighlight = (word) => <strong>{word}</strong>,
}) => {
  if (typeof children !== 'string')
    throw new Error('<Highlighter> children must be a string!')

  const searchExp =
    searchTerm instanceof RegExp ? searchTerm : new RegExp(searchTerm, 'ig')
  const searchResult = children.match(searchExp)

  const splitWords = children.split(searchExp).reduce((acc, curr, idx, arr) => {
    acc.push(curr)

    if (idx < arr.length - 1) acc.push(renderHighlight(searchResult[idx]))

    return acc
  }, [])

  return splitWords
}
