import React from 'react'
import { graphql } from 'gatsby'
import { propPairsEq, getRichText } from 'helpers'
import { uppercase } from 'tiny-compose-fns/noFp'
import css from '@styled-system/css'

import { Box, Grid, Text, Link } from 'system'
import {
  BoundedBox,
  Heading,
  PillButton,
  HTMLContent,
  SpeedBumpLink,
  AirportCircle,
} from 'src/components'

export const PageBodySectionHeader = ({
  title,
  descriptionHTML,
  backButtonHref,
  backButtonText = 'Back',
  buttons,
  airportListSlicesUIDs,
  ...props
}) => {
  return (
    <BoundedBox
      as="section"
      bg="teal.90"
      ptScale={backButtonHref ? 'l' : 'xxl'}
      {...props}
    >
      <Grid
        gridColumnGapScale="m"
        gridRowGapScale={['s', 'l']}
        gridTemplateColumns={['1fr', 'repeat(8, 1fr)']}
      >
        {backButtonHref && (
          <Box gridColumn="1 / -1">
            <Link
              href={backButtonHref}
              color="gray.50"
              fontWeight="semibold"
              transitionProperty="color"
              css={(p) => ({
                '&:hover, &:focus': { color: p.theme.colors.gray[10] },
              })}
            >
              <Text as="span" display="inline-block" mrScale="t">
                &lt;
              </Text>
              {backButtonText}
            </Link>
          </Box>
        )}
        {title && (
          <Heading
            gridColumn={[
              '1 / -1',
              descriptionHTML ? '1 / span 2' : '1 / span 4',
            ]}
          >
            {title}
          </Heading>
        )}
        {descriptionHTML && (
          <HTMLContent
            html={descriptionHTML}
            gridColumn={['1 / -1', '3 / span 4']}
          />
        )}
        {buttons?.length > 0 && airportListSlicesUIDs?.length === 0 && (
          <Grid
            gridGapScale={['t', 's+']}
            gridAutoFlow={[null, null, 'column']}
            gridColumn={['1 / -1', descriptionHTML ? 'span 2' : 'span 4']}
            css={css({ placeContent: ['start', 'end'] })}
          >
            {buttons?.map?.((button) => (
              <PillButton
                as={SpeedBumpLink}
                href={button?.href}
                target={button?.target}
                key={button?.href}
              >
                {button?.text}
              </PillButton>
            ))}
          </Grid>
        )}
        {airportListSlicesUIDs?.length > 0 && (
          <Grid
            gridTemplateColumns={[
              'repeat(auto-fit, 3rem)',
              null,
              null,
              null,
              'repeat(auto-fit, 3.5rem)',
            ]}
            gridGapScale="s"
            gridColumn={[
              '1 / -1',
              null,
              null,
              descriptionHTML ? 'span 2' : 'span 4',
            ]}
            mtScale={['t', null, null, 0]}
          >
            {airportListSlicesUIDs.map((uid) => (
              <AirportCircle
                as={Link}
                key={uid}
                href={`#${uid}`}
                variant="tinyWhite"
                fontSizeScale="t"
                withHover={true}
                outline="none"
              >
                {uppercase(uid)}
              </AirportCircle>
            ))}
          </Grid>
        )}
      </Grid>
    </BoundedBox>
  )
}

PageBodySectionHeader.mapDataToProps = ({
  data,
  context,
  nextContext,
  list,
}) => {
  const airportListSlicesUIDs = list
    .filter((slice) =>
      [
        'PrismicPageBodyAirportProjectsList',
        'PrismicPageBodyAirportClosuresList',
      ].includes(slice.__typename),
    )
    .map((slice) => slice.primary?.airport?.document?.uid)

  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    title: data?.primary?.heading?.text,
    descriptionHTML: getRichText(data?.primary?.description),
    backButtonText: data?.primary?.back_button_text?.text,
    backButtonHref: data?.primary?.back_button_link?.url,
    airportListSlicesUIDs,
    buttons: data?.items
      ?.map?.((item) => ({
        text: item?.button_text?.text,
        href: item?.button_link?.url,
        target: item?.button_link?.target,
      }))
      .filter((item) => item?.href),
  }
}

PageBodySectionHeader.mapDataToContext = () => ({
  bg: 'teal.90',
})

export const fragment = graphql`
  fragment PageBodySectionHeader on Query {
    prismicPage(uid: { eq: $uid }) {
      data {
        body {
          ... on PrismicPageBodySectionHeader {
            id
            primary {
              heading {
                text
              }
              description {
                text
                html
              }
              back_button_text {
                text
              }
              back_button_link {
                url
              }
            }
            items {
              button_text {
                text
              }
              button_link {
                url
                target
              }
            }
          }
        }
      }
    }
  }
`
