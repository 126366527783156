import React from 'react'

import { SVG } from 'system'
import { ReactComponent as AssetIconSearchSVG } from 'src/assets/icon-search.svg'

export const SearchIcon = (props) => {
  return (
    <SVG
      x={1}
      y={1}
      svg={AssetIconSearchSVG}
      width="1.5rem"
      color="green.50"
      {...props}
    />
  )
}
