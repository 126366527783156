import React from 'react'
import { graphql } from 'gatsby'
import { propPairsEq, getRichText, notEmpty } from 'helpers'

import { Grid, Flex, Text, Link } from 'system'
import { BoundedBox, HTMLContent, Rule, Button } from 'src/components'

export const PageBodyNotFound = ({ textHTML, buttons = [], ...props }) => (
  <BoundedBox
    as="section"
    bg="teal.90"
    maxWidth="none"
    pxScale={['m', 'xxl']}
    {...props}
  >
    <Grid
      bg="white"
      pxScale={['l', 'xxl']}
      pyScale="xxl"
      gridGapScale="xl"
      justifyItems="center"
      alignContent="center"
      minHeight="60vh"
    >
      <Grid gridGapScale="m" justifyItems="center">
        <Flex
          width="5.5rem"
          height="5.5rem"
          bg="green.50"
          alignItems="center"
          justifyContent="center"
          borderRadius="50%"
        >
          <Text
            as="span"
            fontFamily="sansExt"
            fontSizeScale="s"
            textStyle="trackedCaps"
            ml="0.1em"
          >
            404
          </Text>
        </Flex>
        <HTMLContent html={textHTML} textAlign="center" maxWidth="40ch" />
      </Grid>
      {notEmpty(buttons) && (
        <>
          <Rule color="green.50" variant="noExtend" width={['75%', '50%']} />
          <Grid
            as="ul"
            gridGapScale="m"
            gridAutoFlow={['row', 'column']}
            alignItems="center"
            justifyItems="center"
          >
            {buttons.map((button, i) => (
              <Grid
                key={button.url}
                as="li"
                gridGapScale="m"
                gridAutoFlow={['row', 'column']}
                alignItems="center"
                justifyItems="center"
              >
                <Button as={Link} href={button.href} target={button.target}>
                  {button.text}
                </Button>
                {i < buttons.length - 1 && (
                  <Text
                    as="span"
                    fontFamily="sansExt"
                    fontSizeScale="xt"
                    lineHeight="solid"
                    textStyle="trackedCaps"
                  >
                    or
                  </Text>
                )}
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Grid>
  </BoundedBox>
)

PageBodyNotFound.mapDataToProps = ({
  data,
  context,
  nextContext,
  previousContext,
}) => {
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    previousSharesBg: propPairsEq('bg', context, previousContext),
    textHTML: getRichText(data?.primary?.text),
    buttons: data?.items?.map?.((item) => ({
      href: notEmpty(item?.button_link?.url) ?? '/',
      target: item?.button_link?.target,
      text: item?.button_text?.text,
    })),
  }
}

PageBodyNotFound.mapDataToContext = () => ({
  bg: 'teal.90',
})

export const fragment = graphql`
  fragment PageBodyNotFound on Query {
    prismicPage(uid: { eq: $uid }) {
      data {
        body {
          ... on PrismicPageBodyNotFound {
            primary {
              text {
                text
                html
              }
            }
            items {
              button_text {
                text
              }
              button_link {
                url
                target
              }
            }
          }
        }
      }
    }
  }
`
