export const delay = async (wait) => {
  return new Promise((resolve) => setTimeout(resolve, wait))
}

export const checkIsMobile = () => {
  if (typeof window === 'undefined') return false

  return window.matchMedia('(max-width: 48rem)').matches
}

// Converts a coordinate represented as an object to a tuple.
export const coordinateObjToTuple = (coordinates) => {
  // Sorry, I know this is dirty but the project needs to ship.
  if (coordinates === undefined) return coordinates

  return [coordinates.longitude, coordinates.latitude]
}

// Takes in a list of projects or closures and a list of ArcGIS features and
// reshapes the list items to be compatible with mapbox/supercluster. If a
// matching ArcGIS feature cannot be found for a list item, it attempts to use
// a fallback coordinate provided by the list item.
//
// `type` should be "project" or "closure".
export const reshapeObjectsForSupercluster = (type, list, features) =>
  list.reduce((acc, curr) => {
    const arcgisId = curr.data.arcgis_id
    const fallbackCoordinates = curr.data.fallback_coordinates
    let feature = features[arcgisId]

    // If the ArcGIS ID does not exist, or one has not been assigned, use the
    // fallback coordinates if available.
    if (
      !feature &&
      fallbackCoordinates?.longitude &&
      fallbackCoordinates?.latitude
    ) {
      feature = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: coordinateObjToTuple(fallbackCoordinates),
        },
      }
    }

    // If we don't have an ArcGIS feature or fallback coordinates, we can't
    // show it on the map. Exclude it from the list.
    if (!feature) return acc

    acc.push({
      ...feature,
      public: curr,
      type,
    })

    return acc
  }, [])

// Validates a coordinate tuple.
export const validateCoordinateTuple = ([longitude, latitude] = []) =>
  Boolean(
    longitude &&
      longitude >= -180 &&
      longitude <= 180 &&
      latitude &&
      latitude >= -90 &&
      latitude <= 90,
  )

// Converts a string to a numeric representation used to sort.
export const strToIntVal = (str) =>
  [...str]
    .map((char) => char.charCodeAt(0))
    .reduce((acc, curr) => acc + curr, 0)

// Converts a date-like string to a numeric representation used to sort.
// Prioritizes a four digit year over any other characters.
export const dateLikeToInt = (dateStr = '') => {
  const parts = String(dateStr).split(' ')
  const maybeYearIndex = parts.findIndex((part) => /\d{4}/.test(part))
  const maybeYear = maybeYearIndex === -1 ? null : parts[maybeYearIndex]

  const nonYearParts =
    maybeYearIndex !== -1
      ? [...parts.slice(0, maybeYearIndex), ...parts.slice(maybeYearIndex + 1)]
      : parts

  const nonYearPartsVal = nonYearParts
    .map((x) => strToIntVal(x))
    .reduce((acc, curr) => acc + curr, 0)

  const int = Number.parseInt(`${maybeYear}${nonYearPartsVal}`)

  return Number.isNaN(int) ? -Infinity : int
}

export const arrsEqual = (a, b) => {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length != b.length) return false

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (let i = 0; i < a.length; ++i) if (a[i] !== b[i]) return false

  return true
}
