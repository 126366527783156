import React from 'react'

import { Box } from 'system'
import { BoundedBox } from 'src/components'

export const OffsetBoundedBox = React.forwardRef(
  (
    { offsetSide = 'left', offsetProps, innerProps, children, ...props },
    ref,
  ) => {
    const isOffsetLeft = offsetSide === 'left'

    return (
      <BoundedBox ref={ref} pxScale={['t', 'm']} {...props}>
        <Box position="relative" height="100%">
          <Box
            position="absolute"
            width={[
              'calc((100vw - 100% - 24px) / 2 + 100%)',
              'calc((100vw - 100%) / 2 + 100%)',
            ]}
            height="100%"
            left={[
              isOffsetLeft && 'calc((100vw - 100% - 24px) / 2 * -1)',
              isOffsetLeft && 'calc((100vw - 100%) / 2 * -1)',
            ]}
            right={[
              !isOffsetLeft && 'calc((100vw - 100% - 24px) / 2 * -1)',
              !isOffsetLeft && 'calc((100vw - 100%) / 2 * -1)',
            ]}
            top={0}
            bg="white"
            {...offsetProps}
          />
          <Box
            position="relative"
            pyScale="xxl"
            plScale={['m', isOffsetLeft ? 0 : 'm']}
            prScale={['m', isOffsetLeft ? 'm' : 0]}
            {...innerProps}
          >
            {children}
          </Box>
        </Box>
      </BoundedBox>
    )
  },
)
