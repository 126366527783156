import { useStaticQuery, graphql } from 'gatsby'

export const useSettingsData = () => {
  const queryData = useStaticQuery(graphql`
    {
      prismicSettings {
        data {
          site_name {
            html
            text
          }
          site_description {
            html
            text
          }
          site_copyright {
            html
            text
          }
          dota_website_link {
            url
          }
          completed_status {
            uid
          }
        }
      }
    }
  `)

  return queryData.prismicSettings?.data
}
