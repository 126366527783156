import React, { useRef } from 'react'
import { usePaginatedCollection } from 'hooks'
import { isInternal } from 'helpers'

import { Box, Grid, Text, Link } from 'system'
import {
  Heading,
  Button,
  NotAvailableLabel,
  Rule,
  Pagination,
} from 'src/components'

export const ListTable = ({
  containerRef,
  emptyMessage,
  paramKey,
  list,
  renderHeadings,
  renderItem,
  renderFooterAccessories,
  perPage = 5,
  ruleVariant,
  withFirstRuleOnMobile = true,
  withReducedHeadingRightPadding = false,
  ...props
}) => {
  const ref = useRef()
  const {
    paginatedCollection,
    totalPages,
    page,
    incPage,
    decPage,
    hasNextPage,
    hasPreviousPage,
  } = usePaginatedCollection({
    containerRef: containerRef ?? ref,
    collection: list,
    perPage,
    paramKey,
  })

  return (
    <Box ref={ref} {...props}>
      <Rule
        color="teal.80"
        height="1px"
        display={[withFirstRuleOnMobile ? null : 'none', 'none']}
      />

      {totalPages < 1 && emptyMessage && (
        <Text color="teal.50" myScale="l" textAlign={[null, 'center']}>
          {emptyMessage}
        </Text>
      )}

      {totalPages > 0 && (
        <>
          <Grid
            gridTemplateColumns={[
              'repeat(2, 1fr)',
              'repeat(12, 1fr)',
              null,
              '1fr 1fr 1fr 1fr 1fr 1fr 1.5fr 1fr 1fr',
            ]}
            gridColumnGapScale="l"
            prScale={withReducedHeadingRightPadding ? ['m', 0] : 'm'}
            pbScale="t"
            alignItems="end"
            display={['none', null, null, 'grid']}
          >
            <Box gridColumn={['1 / -1', null, null, 'span 2']} />
            {renderHeadings()}
          </Grid>

          <Rule
            color="teal.80"
            height="1px"
            display={['none', null, null, 'block']}
          />

          <Grid as="ul" gridRowGap="1px" bg="teal.80">
            {paginatedCollection.map(renderItem)}
          </Grid>
        </>
      )}

      <Rule color="teal.40" variant={ruleVariant} />

      <Grid
        gridColumnGapScale="m"
        gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1.5fr 1fr 1fr"
        alignItems="center"
        mtScale="s+"
      >
        {totalPages > 1 && (
          <Pagination
            page={page + 1}
            totalPages={totalPages}
            incPage={() => incPage()}
            decPage={() => decPage()}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            gridColumn="1 / span 4"
            justifySelf="start"
          />
        )}
        {renderFooterAccessories && (
          <Box gridColumn="5 / -1" justifySelf="end">
            {renderFooterAccessories()}
          </Box>
        )}
      </Grid>
    </Box>
  )
}

ListTable.Heading = (props) => (
  <Text
    textStyle="trackedCapsWide"
    color="gray.50"
    fontSizeScale="t-"
    fontWeight="bold"
    {...props}
  />
)

ListTable.Item = ({ name, href, children, ...props }) => {
  const internal = isInternal(href)

  return (
    <Grid
      as="li"
      bg="white"
      gridColumnGapScale="l"
      gridRowGapScale="t"
      gridTemplateColumns={[
        'repeat(2, 1fr)',
        'repeat(12, 1fr)',
        null,
        '1fr 1fr 1fr 1fr 1fr 1fr 1.5fr 1fr 1fr',
      ]}
      prScale="m"
      pyScale="m"
      color="gray.20"
      {...props}
    >
      <Heading
        as="p"
        gridColumn={['1 / -1', null, null, 'span 2']}
        fontSizeScale={['m', 'b']}
        fontWeight="semibold"
        mbScale="t-"
      >
        <Link
          href={href}
          target={internal ? undefined : '_blank'}
          rel={internal ? undefined : 'noopener noreferrer nofollow'}
          color="gray.10"
          outline="none"
          transitionProperty="color"
          css={(p) => ({
            '&:hover, &:focus': { color: p.theme.colors.teal[40] },
          })}
        >
          {name}
        </Link>
      </Heading>

      {children}

      {href && (
        <>
          <Button
            as={Link}
            display={['none', 'flex']}
            href={href}
            target={internal ? undefined : '_blank'}
            rel={internal ? undefined : 'noopener noreferrer nofollow'}
            justifySelf={['start', 'end']}
            alignSelf="start"
            gridColumn={[null, 'span 2']}
            outline="none"
          >
            More Info
          </Button>
          <Button
            as={Link}
            display={[null, 'none']}
            href={href}
            target={internal ? undefined : '_blank'}
            rel={internal ? undefined : 'noopener noreferrer nofollow'}
            justifySelf={['start', 'end']}
            alignSelf="start"
            gridColumn="span 2"
            withArrowIcon={false}
          >
            More Info
          </Button>
        </>
      )}
    </Grid>
  )
}

ListTable.ItemData = ({ label, children, ...props }) => (
  <Box gridColumn={[null, 'span 2', null, 'span 1']} {...props}>
    <Text
      as="dt"
      fontSizeScale="t"
      color="teal.50"
      textStyle="trackedCapsWide"
      fontWeight="bold"
      display={['block', null, null, 'none']}
    >
      {label}
    </Text>
    <Text as="dd" fontSizeScale="b-">
      {children ?? <NotAvailableLabel />}
    </Text>
  </Box>
)
