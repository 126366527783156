import React from 'react'
import { notEmpty } from 'helpers'

import { Grid, Flex } from 'system'

import {
  AirportCircle,
  Heading,
  IslandSVG,
  KeyValueDetail,
} from 'src/components'

export const ClosureIntro = ({ closureData, airportData }) => (
  <Grid
    gridTemplateColumns={['1fr', null, '2fr 1fr']}
    gridColumnGapScale="l"
    pyScale="m"
    alignItems="end"
  >
    <Grid
      gridTemplateColumns={['1fr', 'repeat(3, 1fr)', 'repeat(3, auto)']}
      justifyContent="start"
      gridColumnGapScale="xl"
      gridRowGapScale="m"
    >
      <KeyValueDetail label="Closure Begins">
        {notEmpty(closureData?.start_date?.text)}
      </KeyValueDetail>
      <KeyValueDetail label="Closure Ends">
        {notEmpty(closureData?.end_date?.text)}
      </KeyValueDetail>
      <KeyValueDetail label="Hours">
        {notEmpty(closureData?.hours?.text)}
      </KeyValueDetail>
    </Grid>

    <Grid
      display={['none', null, 'grid']}
      gridAutoFlow="column"
      alignItems="end"
    >
      <Flex alignItems="center">
        <AirportCircle mrScale="s" width="4rem" height="4rem" fontSizeScale="s">
          {airportData?.code?.text}
        </AirportCircle>
        <Heading fontSizeScale="b-" color="teal.40" maxWidth="20ch">
          {airportData?.full_name?.text}
        </Heading>
      </Flex>
      <IslandSVG
        width="7.75rem"
        display={['none', null, null, 'block']}
        airportCode={airportData?.code?.text}
      />
    </Grid>
  </Grid>
)
