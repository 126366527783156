import React from 'react'

import { BoundedBox as BoundedBoxBase } from 'system'

export const BoundedBox = React.forwardRef(
  ({ nextSharesBg = [false], previousSharesBg = [false], ...props }, ref) => (
    <BoundedBoxBase
      ref={ref}
      ptScale={previousSharesBg?.map?.((x) => (x ? 'l' : 'xxl'))}
      pbScale={nextSharesBg?.map?.((x) => (x ? 0 : 'xxl'))}
      pxScale="m"
      {...props}
    />
  ),
)
