import React from 'react'
import { linearScale } from 'styled-system-scale'

import { Flex, Text } from 'system'
import { theme } from 'src/theme'

const variants = {
  default: {
    scale: linearScale('4rem', '7.5rem', { count: 5 }),
    bg: theme.colors.teal[40],
    color: 'white',
  },
  small: {
    scale: '4rem',
    bg: 'white',
    color: theme.colors.teal[40],
  },
  tinyWhite: {
    scale: '3.5rem',
    bg: 'white',
    color: theme.colors.teal[40],
  },
}

export const AirportCircle = ({
  children,
  fontSizeScale = 'm',
  variant = 'default',
  withHover = false,
  ...props
}) => {
  const { scale, bg, color } = variants[variant] ?? variant.default

  return (
    <Flex
      alignItems="center"
      bg={bg}
      borderRadius="50%"
      color={color}
      flexShrink="0"
      height={scale}
      justifyContent="center"
      width={scale}
      transitionProperty="color, background"
      css={
        withHover && {
          '&:hover, &:focus': {
            background: theme.colors.teal[40],
            color: 'white',
          },
        }
      }
      {...props}
    >
      <Text
        fontFamily="sansExt"
        fontSizeScale={fontSizeScale}
        textAlign="center"
      >
        {children}
      </Text>
    </Flex>
  )
}
