import React, { useEffect, useRef } from 'react'
import { Location } from '@reach/router'
import { useSpring, a } from 'react-spring'
import { isPathActive } from 'helpers'
import { navigate } from 'gatsby'
import queryString from 'query-string'

import { useSettingsData } from 'src/hooks'

import { Text, Grid, Flex, Input, Button } from 'system'
import {
  BoxButton,
  BoundedBox,
  NavLink,
  SearchIcon,
  SpeedBumpLink,
  InputClearIcon,
} from 'src/components'

export const MobileNav = ({
  isOpen,
  navData,
  closeMobileMenu,
  initialQuery = '',
  ...props
}) => {
  const settingsData = useSettingsData()
  const rInput = useRef(null)

  const [{ transform }, set] = useSpring(() => ({
    transform: 'translateY(-100%)',
  }))

  const handleSubmit = (e) => {
    e.preventDefault()
    const query = e.currentTarget.query.value
    document.activeElement?.blur()

    navigate(`/search/?${queryString.stringify({ query })}`)
    closeMobileMenu()
  }

  useEffect(() => {
    if (isOpen) {
      set({ transform: 'translateY(0%)' })
    } else {
      set({ transform: 'translateY(-120%)' })
    }
  }, [isOpen, set])

  return (
    <Grid
      as={a.div}
      gridTemplateRows="auto 1fr auto"
      display={['grid', 'none']}
      style={{ transform }}
      color="gray.10"
      bg="white"
      position="absolute"
      right={0}
      left={0}
      aria-hidden={!isOpen}
      boxShadow="0 1px 2px rgba(0, 0, 0, 0.3), 0 4px 6px rgba(0, 0, 0, 0.2)"
      {...props}
    >
      <BoundedBox bg="green.50" pyScale="m">
        <Flex
          as="form"
          onSubmit={handleSubmit}
          bg="white"
          borderRadius="28px"
          position="relative"
          plScale="m"
        >
          <Input
            defaultValue={initialQuery}
            ref={rInput}
            pyScale="t"
            plScale="m"
            prScale="m"
            outline="none"
            width="100%"
            name="query"
            tabIndex={isOpen ? 0 : -1}
          />
          <SearchIcon
            position="absolute"
            leftScale="t"
            top="50%"
            transform="translateY(-50%)"
          />
          <Button
            type="button"
            position="absolute"
            rightScale="s"
            top="50%"
            transform="translateY(-50%)"
            color="green.50"
            onClick={() => {
              if (!rInput.current) return
              rInput.current.value = ''
              rInput.current.focus()
            }}
            css={(p) => ({
              '&:hover, &:focus': {
                color: p.theme.colors.gray[10],
              },
            })}
          >
            <InputClearIcon color="inherit" />
          </Button>
        </Flex>
      </BoundedBox>

      <Text
        as="nav"
        textStyle="trackedCaps"
        fontWeight="bold"
        fontFamily="sansExt"
        alignSelf="center"
      >
        <BoundedBox as="ul">
          <Location>
            {({ location }) =>
              navData.map((item) => {
                const href = item?.primary?.link?.url
                const target = item?.primary?.link?.target
                const name = item?.primary?.name
                const isActive = isPathActive(href, location)

                return (
                  <NavLink
                    href={href}
                    key={name}
                    target={target}
                    isActive={isActive}
                    colorScheme="dark"
                    fontSizeScale="s"
                    mbScale="l"
                    onClick={closeMobileMenu}
                  >
                    {name}
                  </NavLink>
                )
              })
            }
          </Location>
        </BoundedBox>
      </Text>

      <BoundedBox bg="green.50" pyScale="l">
        <Text mbScale="t" textAlign="center" fontSizeScale="m">
          More airport info available at
        </Text>
        <BoxButton
          as={SpeedBumpLink}
          href={settingsData?.dota_website_link?.url}
          target="_blank"
          colorScheme="dark"
          width="100%"
        >
          DOT Airports
        </BoxButton>
      </BoundedBox>
    </Grid>
  )
}
