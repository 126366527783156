import React from 'react'

import { Box, Text } from 'system'
import { NotAvailableLabel } from 'src/components'

export const MetadataItem = ({ label, children, ...props }) => (
  <Box {...props}>
    <Text
      as="dt"
      fontSizeScale="t"
      color="teal.50"
      textStyle="trackedCapsWide"
      fontWeight="bold"
      display={['block', null, null, 'none']}
    >
      {label}
    </Text>
    <Text as="dd" fontSizeScale={['b-', null, null, null, 'b']}>
      {children ?? <NotAvailableLabel />}
    </Text>
  </Box>
)
