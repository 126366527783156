import React from 'react'
import { Grid, Flex } from 'system'

import { AirportCircle, Heading, IslandSVG } from 'src/components'

export const AirportGraphic = ({ code, name, ...props }) => {
  return (
    <Grid gridAutoFlow="column" alignItems="end" {...props}>
      <Flex alignItems="center">
        <AirportCircle mrScale="s" width="4rem" height="4rem" fontSizeScale="s">
          {code}
        </AirportCircle>
        <Heading fontSizeScale="b-" color="teal.40" maxWidth="20ch">
          {name}
        </Heading>
      </Flex>
      <IslandSVG width="7.75rem" airportCode={code} />
    </Grid>
  )
}
