import React, { useRef, useState, useEffect, useMemo } from 'react'
import turfBbox from '@turf/bbox'
import {
  InteractiveMap,
  WebMercatorViewport,
  FlyToInterpolator,
} from 'react-map-gl'

import { MAPBOX_TOKEN, MAPBOX_STYLE_URI } from 'src/constants'
import { useAirports, useProjects, useClosures } from 'src/hooks'
import { useMapReducer } from '../mapReducer'

import { Box } from 'system'
import { MapNavigationControls, MapMarkers } from 'src/components'

const bboxToBounds = ([minX, minY, maxX, maxY]) => [
  [minX, minY],
  [maxX, maxY],
]

/**
 * Converts a collection of GeoJSON objects (i.e. the things that would
 * normally go inside a Feature) to a bbox.
 */
const objectsToBbox = (objects) => {
  const featureCollection = {
    type: 'FeatureCollection',
    features: objects.map((object) => ({
      type: 'Feature',
      geometry: object,
    })),
  }

  return turfBbox(featureCollection)
}

/**
 * Returns a viewport that fits an airport's bounds.
 */
const getAirportViewport = (mapboxGl, airport) => {
  const points = airport?.data?.map_bounded_box?.map((mapBB) => ({
    type: 'Point',
    coordinates: [
      mapBB?.map_bounded_box_coordinate?.longitude,
      mapBB?.map_bounded_box_coordinate?.latitude,
    ],
  }))

  const containerRect = mapboxGl.getContainer().getBoundingClientRect()

  return new WebMercatorViewport({
    width: containerRect.width,
    height: containerRect.height,
  }).fitBounds(bboxToBounds(objectsToBbox(points)), {
    padding: {
      left: window.innerWidth * 0.05,
      right: window.innerWidth * 0.05,
      top: window.innerHeight * 0.05,
      bottom: window.innerHeight * 0.05,
    },
  })
}

export const Map = (props) => {
  const [state, dispatch] = useMapReducer()
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const [viewport, setViewport] = useState()
  const map = useRef()

  const airports = useAirports()
  const projects = useProjects()
  const closures = useClosures()

  /**
   * Active airport based on state.
   */
  const activeAirport = useMemo(
    () => airports.find((airport) => airport.uid === state.activeAirport),
    [airports, state.activeAirport],
  )

  /**
   * Active transition props based on state.
   */
  const transitionProps = useMemo(() => {
    switch (state.state) {
      case 'AIRPORT_VIEW': {
        return {
          transitionInterpolator: new FlyToInterpolator({ speed: 2 }),
          transitionDuration: 'auto',
        }
      }

      case 'REST_VIEW':
      default: {
        return {}
      }
    }
  }, [state.state])

  /**
   * Initialize reducer and set viewport to initial activeAirport.
   */
  useEffect(() => {
    if (isMapLoaded && state.state === 'INITIALIZING') {
      const mapboxGl = map.current.getMap()

      dispatch({
        type: 'INITIALIZE',
        payload: {
          map: mapboxGl,
          markers: [...projects, ...closures],
        },
      })

      const airportViewport = getAirportViewport(mapboxGl, activeAirport)
      setViewport(airportViewport)
    }
  }, [
    isMapLoaded,
    dispatch,
    closures,
    projects,
    state.state,
    activeAirport,
    state.map,
  ])

  /**
   * On AIRPORT_VIEW, set viewport to airport bbox.
   */
  useEffect(() => {
    if (state.state === 'AIRPORT_VIEW') {
      const airportViewport = getAirportViewport(state.map, activeAirport)
      setViewport(airportViewport)
    }
  }, [state.map, state.state, activeAirport])

  /**
   * Debug: log state changes.
   */
  useEffect(() => {
    if (process.env.NODE_ENV === 'development')
      console.log(`CHANGED STATE TO: ${state.state}`, state)
  }, [state])

  /**
   * Imperatively set state to REST_VIEW after transitions.
   */
  const onTransitionEnd = () => dispatch({ type: '_IMPERATIVE_SET_REST_VIEW' })

  /**
   * Imperatively set state to REST_VIEW when clicking on the base map.
   */
  const onInteraction = () => {
    if (event.target?.className === 'overlays') {
      dispatch({ type: '_IMPERATIVE_SET_REST_VIEW' })
    }
  }

  return (
    <Box bg="gray.80" gridTemplateRows="1fr auto" {...props}>
      <InteractiveMap
        {...viewport}
        {...transitionProps}
        ref={map}
        width="100%"
        height="100%"
        scrollZoom={false}
        reuseMaps={true}
        maxZoom={18}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        mapStyle={MAPBOX_STYLE_URI}
        onLoad={() => setIsMapLoaded(true)}
        onViewportChange={(newViewport) => setViewport(newViewport)}
        onTransitionEnd={onTransitionEnd}
        onMouseDown={onInteraction}
        onTouchStart={onInteraction}
      >
        <MapNavigationControls setViewport={setViewport} />
        <MapMarkers />
      </InteractiveMap>
    </Box>
  )
}
