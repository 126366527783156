import React from 'react'
import {
  Tabs as ReachTabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@reach/tabs'
import css from '@styled-system/css'

import { Box, Grid, Text, Button } from 'system'
import { Subheading, Rule } from 'src/components'

const TabOption = ({ isSelected, shortName, name, ...props }) => (
  <Button
    as={Tab}
    borderBottom="5px solid"
    borderColor={isSelected ? 'teal.40' : 'transparent'}
    transitionProperty="border-color"
    {...props}
    css={css({ '&:hover, &:focus': { '.tab-name': { color: 'teal.60' } } })}
  >
    <Subheading
      color={isSelected ? 'teal.60' : 'gray.70'}
      lineHeight="solid"
      transitionProperty="color"
      mbScale="t-"
      className="tab-name"
    >
      <Text as="span" display={[null, null, 'none']}>
        {shortName ?? name}
      </Text>
      <Text as="span" display={['none', null, 'inline']}>
        {name}
      </Text>
    </Subheading>
  </Button>
)

export const Tabs = ({
  children,
  renderAccessories,
  ruleVariant,
  ...props
}) => {
  const tabs = React.Children.toArray(children)

  return (
    <Box {...props}>
      <Grid
        as={ReachTabs}
        gridColumnGapScale="m"
        gridTemplateColumns={['1fr', 'repeat(8, 1fr)']}
      >
        <Grid
          as={TabList}
          gridAutoFlow="column"
          justifyContent="start"
          gridGapScale={['s-', 'm']}
          gridColumn={['1 / -1', '1 / span 5']}
          alignSelf="end"
        >
          {tabs.map((tab) => (
            <TabOption
              key={tab.key}
              name={tab.props.name}
              shortName={tab.props.shortName}
            />
          ))}
        </Grid>

        <Rule
          color="teal.40"
          gridColumn="1 / -1"
          gridRow={2}
          variant={ruleVariant}
        />

        {renderAccessories && (
          <Box
            gridColumn={['1 / -1', '6 / -1']}
            gridRow={[3, 1]}
            alignSelf="center"
          >
            {renderAccessories()}
          </Box>
        )}

        <Box as={TabPanels} gridColumn="1 / -1">
          {tabs.map((tab) => (
            <TabPanel key={tab.key} style={{ outline: 'none' }}>
              {tab.props.children}
            </TabPanel>
          ))}
        </Box>
      </Grid>
    </Box>
  )
}

// Props passed to Tabs.Tab are used in Tabs. This is just a nicer abstraction
// over using @reach/tabs directly.
Tabs.Tab = ({ children }) => children
