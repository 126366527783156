import React, { useRef } from 'react'
import { graphql } from 'gatsby'

import { MapControls, Map, MapDrawerButton } from 'src/components'
import { Grid } from 'system'

import { MapReducerProvider } from '../mapReducer'

export const PageBodyMap = ({ airportsOrder, ...props }) => {
  const containerRef = useRef()

  const scrollBelowMap = () => {
    const container = containerRef.current

    if (!container) return
    const nextElement = container.nextElementSibling

    if (!nextElement) return
    nextElement.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Grid
      ref={containerRef}
      as="section"
      height={['calc(88vh - 70px)', 'calc(88vh - 96px)', 'auto']}
      gridTemplateRows={['auto 1fr', null, 'auto']}
      gridTemplateColumns={['1fr', null, '26rem 1fr', null, '32rem 1fr']}
      minHeight="30rem"
      maxHeight={['40rem', null, '60rem']}
      {...props}
    >
      <MapReducerProvider>
        <MapControls airportsOrder={airportsOrder} />
        <Map />
      </MapReducerProvider>
      <MapDrawerButton
        onClick={scrollBelowMap}
        text="More Info"
        display={['flex', 'none']}
      />
    </Grid>
  )
}

PageBodyMap.mapDataToProps = ({ data }) => ({
  airportsOrder: data?.items
    ?.map?.((item) => item?.airport?.document?.uid)
    .filter(Boolean),
})

export const fragment = graphql`
  fragment PageBodyMap on Query {
    prismicPage(uid: { eq: $uid }) {
      data {
        body {
          ... on PrismicPageBodyMap {
            id
            items {
              airport {
                document {
                  ...Airport
                }
              }
            }
          }
        }
      }
    }
  }
`
