import { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { notEmpty } from 'helpers'

import { dateLikeToInt } from '../utils'

export const useClosures = ({
  airportUID,
  statusUID,
  negateStatusUID,
  statusFilters = [],
  zoneFilters = [],
  closureFilters = [],
  sortBy = 'name',
} = {}) => {
  const queryData = useStaticQuery(graphql`
    {
      allPrismicClosure(sort: { fields: [data___title___text] }) {
        nodes {
          ...Closure
        }
      }
    }
  `)

  return useMemo(() => {
    let closures = queryData?.allPrismicClosure?.nodes

    if (airportUID)
      closures = closures?.filter?.(
        (p) => p?.data?.airport?.document?.uid === airportUID,
      )

    if (statusUID)
      closures = closures?.filter?.(
        (p) => p?.data?.status?.document?.uid === statusUID,
      )

    if (statusFilters.length)
      closures = closures.filter((p) =>
        statusFilters.includes(p?.data?.status?.document?.uid),
      )

    if (zoneFilters.length)
      closures = closures.filter((p) =>
        zoneFilters.includes(p?.data?.zone?.document?.uid),
      )

    if (closureFilters.length)
      closures = closures.filter((p) => closureFilters.includes(p?.uid))

    if (negateStatusUID)
      closures = closures?.filter?.(
        (p) => p?.data?.status?.document?.uid !== negateStatusUID,
      )

    switch (sortBy) {
      case 'begin': {
        closures = [...closures].sort(
          (a, b) =>
            dateLikeToInt(a?.fields?.start_date) -
            dateLikeToInt(b?.fields?.start_date),
        )
        break
      }

      case 'end': {
        closures = [...closures].sort(
          (a, b) =>
            dateLikeToInt(a?.fields?.end_date) -
            dateLikeToInt(b?.fields?.end_date),
        )
        break
      }

      case 'zone': {
        closures = [...closures].sort((a, b) => {
          const aZone = notEmpty(a?.data?.zone?.document?.uid)
          const bZone = notEmpty(b?.data?.zone?.document?.uid)

          if (!aZone && bZone) return -1
          if (aZone && !bZone) return 1

          if (aZone < bZone) return -1
          if (aZone > bZone) return 1

          return 0
        })
      }

      case 'name':
      default: {
        closures = [...closures].sort((a, b) => {
          const aName = notEmpty(a?.data?.title?.text)
          const bName = notEmpty(b?.data?.title?.text)

          if (!aName && bName) return 1
          if (aName && !bName) return -1

          if (aName > bName) return 1
          if (aName < bName) return -1

          return 0
        })
      }
    }

    return closures
  }, [
    queryData,
    airportUID,
    negateStatusUID,
    closureFilters,
    sortBy,
    statusFilters,
    statusUID,
    zoneFilters,
  ])
}
