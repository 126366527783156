import React from 'react'

import { Flex, Text, Button as ButtonBase, SVG } from 'system'
import { theme } from 'src/theme'
import { ReactComponent as AssetIconExternalSVG } from 'src/assets/icon-external.svg'

const colorSchemes = {
  blue: {
    color: 'teal.60',
    borderColor: 'teal.60',
    bg: 'transparent',
    hoverBg: theme.colors.teal[60],
    hoverColor: 'white',
  },
  dark: {
    color: 'gray.10',
    borderColor: 'gray.10',
    bg: 'transparent',
    hoverBg: theme.colors.gray[10],
    hoverColor: 'white',
  },
}

export const BoxButton = ({ children, colorScheme = 'blue', ...props }) => {
  const scheme = colorSchemes[colorScheme] ?? colorScheme.blue

  return (
    <Flex
      as={ButtonBase}
      textStyle="trackedCaps"
      border="2px solid"
      bg={scheme.bg}
      borderColor={scheme.borderColor}
      color={scheme.color}
      pyScale="t"
      pxScale="s+"
      alignItems="center"
      fontWeight="bold"
      lineHeight="solid"
      fontSizeScale="t"
      fontFamily="sansExt"
      borderRadius="10em"
      justifyContent="space-between"
      textStyle="trackedCapsWide"
      transitionProperty="background, color"
      css={`
        &:hover,
        &:focus {
          background-color: ${scheme.hoverBg};
          color: ${scheme.hoverColor};
        }
      `}
      {...props}
    >
      <Text mrScale="xl" css={{ whiteSpace: 'nowrap' }}>
        {children}
      </Text>
      <SVG x={1} y={1} svg={AssetIconExternalSVG} width="1rem" />
    </Flex>
  )
}
