import React, { useState, useCallback } from 'react'

import { Flex, Text, Input, SVG } from 'system'
import { ReactComponent as AssetIconArrowDownSVG } from 'src/assets/icon-arrow-down.svg'

export const SortSelect = ({ options = {}, onChange, value, ...props }) => {
  const [curr, setCurr] = useState(Object.values(options)[0])
  const handleChange = useCallback(
    (event) => {
      onChange(event)

      const value = event.target.value
      setCurr(options[value])
    },
    [onChange, options],
  )

  return (
    <Flex position="relative" alignItems="center" {...props}>
      <Input
        as="select"
        id="table-filter"
        value={value}
        onChange={handleChange}
        bg="#f0f"
        border={0}
        cursor="pointer"
        height="100%"
        left={0}
        opacity={0}
        position="absolute"
        top={0}
        width="100%"
      >
        {Object.entries(options).map(([value, label]) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Input>

      <Text
        as="span"
        color="teal.40"
        fontFamily="inherit"
        fontSizeScale="t"
        fontWeight="bold"
        lineHeight="solid"
        outline="none"
        textStyle="trackedCapsWide"
        mrScale="t-"
      >
        {curr}
      </Text>

      <SVG
        svg={AssetIconArrowDownSVG}
        x={1}
        y={1}
        width="0.55rem"
        color="teal.40"
        css={{ pointerEvents: 'none' }}
      />
    </Flex>
  )
}
