import React, { useMemo } from 'react'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs'

import { useMapReducer } from '../mapReducer'

import { Box, Flex, Grid, Text } from 'system'
import { theme } from 'src/theme'
import { useZones, useStatuses } from 'src/hooks'
import { MapControlsBoundedBox, Checkbox } from 'src/components'

const MapTabButton = ({ children, isFirst, isSelected, ...props }) => (
  <Tab
    isSelected={isSelected}
    css={`
      background: ${(p) => p.theme.colors.gray[10]};
      color: ${(p) => (p.isSelected ? 'white' : p.theme.colors.gray[40])};

      &:hover,
      &:focus {
        color: ${(p) => p.theme.colors.white};
      }
    `}
    {...props}
  >
    <Text
      bg="gray.10"
      color="inherit"
      pyScale="t"
      plScale={isFirst ? 's+' : 's'}
      prScale="s"
      textStyle="trackedCapsVeryWide"
      fontWeight="bold"
      fontSizeScale="t-"
      textAlign="left"
      fontFamily="sans"
      borderBottom="1px solid"
      borderBottomColor={isSelected ? 'transparent' : 'black'}
      borderLeft={isFirst ? 'none' : '1px solid'}
      borderLeftColor="black"
      transitionProperty="color"
      {...props}
    >
      {children}
    </Text>
  </Tab>
)

const MapTabPanel = ({
  children,
  withSingleColumn = false,
  emptyMessage,
  ...props
}) => {
  const hasChildren = React.Children.count(children) > 0

  return (
    <TabPanel style={{ position: 'relative', outline: 'none' }} {...props}>
      {hasChildren ? (
        <Box>
          <MapControlsBoundedBox
            color="white"
            height="12rem"
            overflowY="auto"
            withBottomBorder={[false]}
            style={{ overscrollBehavior: 'contain' }}
          >
            <Grid
              gridTemplateColumns={[
                '1fr',
                null,
                withSingleColumn ? '1fr' : '1fr 1fr',
              ]}
              gridGapScale="s"
            >
              {children}
              {/* ensures spacing for the gradient */}
              <Box gridColumn="1 / -1" />
            </Grid>
            <Box
              position="absolute"
              bottom={0}
              left={0}
              right={0}
              height="1.5rem"
              backgroundImage={`linear-gradient(to top, ${theme.colors.gray[10]} 35%, ${theme.colors.gray['10transparent']} 100%)`}
            />
          </MapControlsBoundedBox>
        </Box>
      ) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          height="12rem"
          pbScale="s"
        >
          <Text textAlign="center" color="gray.40" fontSizeScale="s">
            {emptyMessage}
          </Text>
        </Flex>
      )}
    </TabPanel>
  )
}

const markerFilterLabels = {
  closure: 'Closures',
  project: 'Projects',
}

export const FilterControls = () => {
  const [state, dispatch] = useMapReducer()

  const statuses = useStatuses()
  const zones = useZones()

  const filteredMarkers = useMemo(
    () =>
      state.markers.filter(
        (marker) =>
          marker.airport === state.activeAirport &&
          marker.type === state.activeType,
      ),
    [state.activeAirport, state.activeType, state.markers],
  )

  const applicableZones = useMemo(() => {
    const markerZones = new Set(filteredMarkers.map((marker) => marker.zone))
    return zones.filter((zone) => markerZones.has(zone.uid))
  }, [filteredMarkers, zones])

  const applicableStatuses = useMemo(() => {
    const markerStatuses = new Set(
      filteredMarkers.map((marker) => marker.status),
    )
    return statuses.filter((status) => markerStatuses.has(status.uid))
  }, [filteredMarkers, statuses])

  const filterByStatus = (event) =>
    event.target.checked
      ? dispatch({ type: 'FILTER_BY_STATUS', payload: event.target.name })
      : dispatch({ type: 'UNFILTER_BY_STATUS', payload: event.target.name })

  const filterByZone = (event) =>
    event.target.checked
      ? dispatch({ type: 'FILTER_BY_ZONE', payload: event.target.name })
      : dispatch({ type: 'UNFILTER_BY_ZONE', payload: event.target.name })

  const filterByProject = (event) =>
    event.target.checked
      ? dispatch({ type: 'FILTER_BY_PROJECT', payload: event.target.name })
      : dispatch({ type: 'UNFILTER_BY_PROJECT', payload: event.target.name })

  const filterByClosure = (event) =>
    event.target.checked
      ? dispatch({ type: 'FILTER_BY_CLOSURE', payload: event.target.name })
      : dispatch({ type: 'UNFILTER_BY_CLOSURE', payload: event.target.name })

  const activeTypeLabel = markerFilterLabels[state.activeType]

  return (
    <Tabs>
      <Grid as={TabList} gridTemplateColumns="repeat(3, 1fr)">
        <MapTabButton isFirst={true}>Status</MapTabButton>
        <MapTabButton>Zone</MapTabButton>
        <MapTabButton>{activeTypeLabel}</MapTabButton>
      </Grid>

      <TabPanels>
        <MapTabPanel emptyMessage="No available statuses">
          {state.activeType === 'closures' ? (
            <Flex
              alignItems="center"
              justifyContent="center"
              pbScale="s"
              height="10rem"
              gridColumn="1 / -1"
            >
              <Text as="p" textAlign="center" color="white" fontSizeScale="s">
                Showing all current closures
              </Text>
            </Flex>
          ) : (
            applicableStatuses.map((status) => (
              <Checkbox
                label={status.data?.title?.text}
                key={status.uid}
                name={status.uid}
                checked={state.activeStatuses.includes(status.uid)}
                onChange={filterByStatus}
              />
            ))
          )}
        </MapTabPanel>

        <MapTabPanel emptyMessage="No available zones">
          {applicableZones.map((zone) => (
            <Checkbox
              label={zone.data?.name?.text}
              key={zone.uid}
              name={zone.uid}
              checked={state.activeZones.includes(zone.uid)}
              onChange={filterByZone}
            />
          ))}
        </MapTabPanel>

        <MapTabPanel
          withSingleColumn={true}
          emptyMessage={`No available ${activeTypeLabel.toLowerCase()}s`}
        >
          {filteredMarkers.map((marker) => (
            <Checkbox
              label={marker.title}
              key={marker.uid}
              name={marker.uid}
              checked={
                state.activeType === 'project'
                  ? state.activeProjects.includes(marker.uid)
                  : state.activeClosures.includes(marker.uid)
              }
              readOnly={true}
              onChange={
                state.activeType === 'project'
                  ? filterByProject
                  : filterByClosure
              }
            />
          ))}
        </MapTabPanel>
      </TabPanels>
    </Tabs>
  )
}
