import React from 'react'

import { SVG } from 'system'
import { ReactComponent as AssetClosureIcon } from 'src/assets/icon-map-closure.svg'
import { ReactComponent as AssetProjectIcon } from 'src/assets/icon-map-project.svg'
import { ReactComponent as AssetClusterIcon } from 'src/assets/icon-map-cluster.svg'
import { ReactComponent as AssetClosureInvertedIcon } from 'src/assets/icon-map-closure-inverted.svg'
import { ReactComponent as AssetProjectInvertedIcon } from 'src/assets/icon-map-project-inverted.svg'
import { ReactComponent as AssetClusterInvertedIcon } from 'src/assets/icon-map-cluster-inverted.svg'

// const icons = {
//   closure: AssetClosureIcon,
//   project: AssetProjectIcon,
//   cluster: AssetClusterIcon,
// }

const icons = {
  closure: AssetClosureInvertedIcon,
  project: AssetProjectInvertedIcon,
  cluster: AssetClusterInvertedIcon,
}

export const MapIcon = ({ type = 'project', ...props }) => {
  const icon = icons[type] || icons.project

  return <SVG x={1} y={1} width="2.25rem" svg={icon} {...props} />
}
