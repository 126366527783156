import { LinearInterpolator, FlyToInterpolator } from 'react-map-gl'

export const DEFAULT_LAYER_FILL_COLOR = '#cfd454'

export const SHOW_STATES = {
  CLOSURES: 'closure',
  PROJECTS: 'project',
}

export const FILTER_STATES = {
  STATUS: 0,
  ZONE: 1,
  PROJECT: 2,
}

export const DEFAULT_MAP_VIEWPORT = {
  latitude: 21.332453451788137,
  longitude: -157.91985940312026,
  zoom: 13,
  pitch: 27.72803932158727,
}

export const MAP_OVERLAY_FILL_DEFAULTS = {
  'fill-opacity': 0.8,
}

export const MAP_OVERLAY_LINE_DEFAULTS = {
  'line-width': 1.5,
}

export const MAP_LINEAR_INTERPOLATOR_PROPS = {
  transitionEasing: (t) => -(Math.cos(Math.PI * t) - 1) / 2,
  transitionDuration: 500,
  transitionInterpolator: new LinearInterpolator(),
}

export const MAP_FLY_TO_INTERPOLATOR_PROPS = {
  transitionInterpolator: new FlyToInterpolator({ speed: 2 }),
  transitionDuration: 'auto',
}

export const ZOOM_MAP = {
  Default: 16,
  'Default +1': 17,
  'Default -1': 15,
  'Default -2': 14,
  'Default -3': 13,
}

export const MAPBOX_TOKEN = process.env.GATSBY_MAPBOX_ACCESS_TOKEN

export const MAPBOX_STYLE_URI =
  'mapbox://styles/hnlmodernizationwebsite/ckaitra7n03s91ipcgb6mhqln'
